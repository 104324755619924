<span class="troi-dropdown-list"
      [ngClass]="{'troi-dropdown-list--static': static, 'troi-dropdown-list--disabled': disable}">
  <span class="troi-dropdown-list__select"
        (click)="onClickSelect($event)"
        #troiDropDownListSelect>
    <ng-content>
    </ng-content>
    <span class="troi-dropdown-list__list-wrapper"
          [ngClass]="{
                'troi-dropdown-list__list-wrapper--top': top,
                'troi-dropdown-list__list-wrapper--no-min-width': noMinWith}"
          [ngStyle]="{'width': width}"
          *ngIf="openState && options && options.length">
        <input type="text"
               *ngIf="search"
               (click)="searchClicked($event)"
               (keyup)="filterOptions(searchValue)"
               [(ngModel)]="searchValue"
               placeholder="{{'Booking.labels.search' | translate}}..."
               class="troi-dropdown-list__search-input" />
        <span class="troi-dropdown-list__list"
              (focusout)="!search && hideList()"
              tabindex="0">
            <ng-container
                  *ngFor="let option of filteredOptions">
                <troi-dropdown-option
                    [disabled]="option.disabled"
                    [selected]="option.value === innerValue"
                    [label]="option.label"
                    [value]="option.value"
                    [active]="option.active"
                    [count]="option.count"
                    [loading]="option.loading"
                    [group]="option.group"
                    [hasOverride]="option.hasOverride"
                    (click)="!option.disabled && onClickItem(option.value, $event)">
                </troi-dropdown-option>
            </ng-container>
        </span>
    </span>
    <span class="troi-dropdown-list__list-wrapper"
          *ngIf="openState && ((options && !options.length) || !options)">
        <input type="text"
               *ngIf="search"
               (click)="searchClicked($event)"
               (keyup)="filterOptions(searchValue)"
               [(ngModel)]="searchValue"
               class="troi-dropdown-list__search-input" />
        <span class="troi-dropdown-list__list"
              [ngClass]="{
                    'top': top,
                    'noMinWith': noMinWith
                  }"
              [ngStyle]="{'width': width}">
            <ng-content select="[options]"></ng-content>
        </span>
    </span>
  </span>
</span>
