import {Component, Input, OnInit} from '@angular/core';
import {TroiNotification, TroiNotificationType} from '../../core/notifications/notification';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageListener } from '../../core/storage/storage.listener';

@Component({
    selector: 'header-notification',
    templateUrl: './headerNotification.component.html',
    styleUrls: ['./headerNotification.component.scss']
})
export class HeaderNotificationComponent implements OnInit {
    @Input('asToast') public asToast = true;
    public showNotification = false;
    public notification: TroiNotification;

    public constructor(private storageListener: StorageListener) {
        storageListener.storageChanges.subscribe((notificationData: any) => {
            if (notificationData.key === StorageKeys.NOTIFICATION) {
                this.displayNotification(notificationData.value);
                this.storageListener.clear('notification');
            }
        });
    }

    public isError() {
        return this.notification.type === TroiNotificationType.ERROR;
    }

    public isWarning() {
        return this.notification.type === TroiNotificationType.WARNING;
    }

    public isInfo() {
        return this.notification.type === TroiNotificationType.INFO;
    }

    public isSuccess() {
        return this.notification.type === TroiNotificationType.SUCCESS;
    }

    public closeNotification() {
        this.showNotification = false;
    }

    private displayNotification(notification: TroiNotification) {
        this.notification = notification;
        this.showNotification = true;
        setTimeout(function () {
            this.closeNotification();
        }.bind(this), 10000);
    }

    public ngOnInit(): void {}
}
