import {
    Component, EventEmitter, HostListener,
    Input, OnInit, Output, ElementRef, OnChanges
} from '@angular/core';
import { TroiDropDownCloseService } from './services/troi-dropdown-close.service';
import { TroiDropdownListModel } from './models/troi-dropdown-list.model';
import * as _ from 'lodash';

@Component({
    selector: 'troi-dropdown-list',
    templateUrl: './troi-dropdown-list.component.html',
    styleUrls: ['./troi-dropdown-list.component.scss']
})

export class TroiDropdownListComponent implements OnChanges {
    @Input() public options: Array<TroiDropdownListModel>;
    @Input() public static: Boolean = false;
    @Input() public forceOpen = false;
    @Input() public width: String = '100%';
    @Input() public value: any;
    @Input() public top = false;
    @Input() public noMinWith = false;
    @Input() public disable = false;
    @Input() public search = false;
    @Output() selectedEmitter = new EventEmitter<object>();
    @Output() openChange = new EventEmitter<Boolean>();
    private element: ElementRef;
    public innerValue: any;
    public openState = false;
    public searchValue = '';
    public filteredOptions: Array<TroiDropdownListModel>;

    constructor(element: ElementRef,
        private troiDropDownCloseServiceset: TroiDropDownCloseService) {
        this.element = element;
        this.troiDropDownCloseServiceset.getEmitter().subscribe((event) => {
            const clickedElement = _.find(event.path, (path) => {
                return path === this.element.nativeElement;
            });
            if (!clickedElement) {
                this.hideList();
            }
            event.stopPropagation();
        });
    }

    private navigationKeys = ['ArrowUp', 'ArrowDown', ' '];

    private activeOptions(): Array<TroiDropdownListModel> {
        return this.options.filter(option => (
            option.active && !option.disabled
        ));
    }

    public onClickOutside(event): void {
        if (this.openState) {
            this.hideList();
        }
    }

    public onClickSelect(event): void {
        this.troiDropDownCloseServiceset.close(event);
        this.toggleList();
        event.stopPropagation();
    }

    public onClickItem(value, event): void {
        this.hideList();
        this.selectedEmitter.emit({ value, event });
        this.innerValue = value;
        event.stopPropagation();
    }

    private toggleList(): void {
        this.openState = !this.openState;
        this.openChange.emit(this.openState);
        this.initList();
    }

    public hideList(): void {
        this.openState = false;
        this.openChange.emit(this.openState);
        this.searchValue = '';
        this.filterOptions('');
    }

    private setFocus(): void {
        setTimeout(() => {
            this.element.nativeElement.querySelectorAll('.troi-dropdown-list__list')[0].focus();
        }, 0);
    }

    private scrollToSelected(): void {
        setTimeout(() => {
            const list =  this.element.nativeElement.querySelectorAll('.troi-dropdown-list__list')[0];
            const selected = list.querySelectorAll('.troi-dropdown-option--selected')[0];
            if (list && selected) {
                const scrollDown = selected.offsetTop - list.offsetHeight + selected.offsetHeight;
                if (list.scrollTop < scrollDown) {
                    list.scrollTop = scrollDown;
                } else if (list.scrollTop > selected.offsetTop) {
                    list.scrollTop = selected.offsetTop;
                }
            }
        }, 0);
    }

    @HostListener('keydown.space', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (this.search) {
            return;
        }
        this.selectedEmitter.emit({value: this.innerValue, event});
        this.openState = false;
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('keydown.arrowup', ['$event'])
    onArrowUpHandler(event: KeyboardEvent) {
        this.selectOptionByIndex(
            this.findIndexOfElement(this.innerValue, this.activeOptions()) - 1
        );

        this.scrollToSelected();
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('keydown.arrowdown', ['$event'])
    onArrowDownHandler(event: KeyboardEvent) {
        this.selectOptionByIndex(
            this.findIndexOfElement(this.innerValue, this.activeOptions()) + 1
        );
        this.scrollToSelected();
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('keydown', ['$event'])
    onKeyDownHandler(event: KeyboardEvent) {
        if (this.search) {
            return;
        }
        if (this.navigationKeys.indexOf(event.key) === -1) {
            const filteredOptions = this.getFilteredOptions(event.key);
            if (this.innerValue
                && filteredOptions.length > 1
                && this.findIndexOfElement(this.innerValue, filteredOptions) > -1
                && filteredOptions[this.findIndexOfElement(this.innerValue, filteredOptions) + 1]
            ) {
                this.innerValue = filteredOptions[this.findIndexOfElement(this.innerValue, filteredOptions) + 1].value;
            } else if (filteredOptions.length > 0) {
                this.innerValue = filteredOptions[0].value;
            }
            this.value = this.innerValue;
            this.scrollToSelected();
        }

        event.preventDefault();
        event.stopPropagation();
    }

    private getFilteredOptions(key): Array<TroiDropdownListModel> {
        return this.activeOptions().filter(
            option => option.label[0].toLowerCase() === key.toLowerCase()
        );
    }

    private findIndexOfElement(element: string, list: Array<TroiDropdownListModel>): number {
        return  _.findIndex(list, function(option) { return option.value === element; });
    }

    private selectOptionByIndex(index: number): void {
        const activeOptions = this.activeOptions();
        if (activeOptions[index]) {
            this.value = activeOptions[index].value;
            this.innerValue = activeOptions[index].value;
        }
    }

    private initList(): void {
        if (this.openState) {
            this.innerValue = this.value;
            this.setFocus();
            if (this.innerValue) {
                this.scrollToSelected();
            }
        }
        this.filteredOptions = this.options;
    }

    ngOnChanges(changes) {
        if (changes.forceOpen) {
            this.openState = this.forceOpen;
            this.initList();
        }
    }

    searchClicked(event): void {
        event.stopPropagation();
        this.openState = true;
    }

    filterOptions(searchString) {
        if (!searchString) {
            this.filteredOptions = this.options;
            return;
        }
        let returnNextGroup = false;
        this.filteredOptions = _.reverse(this.options).filter(option => {
            if (returnNextGroup && option.group) {
                returnNextGroup = false;
                return true;
            }
            const foundOption = !option.group && option.label.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
            if (foundOption) {
                returnNextGroup = foundOption;
            }
            return foundOption;
        });
        _.reverse(this.filteredOptions);
    }
}
