<span class="modal-footer">
  <span class="modal-footer__options">
    <ng-content select="[footer-options]">
    </ng-content>
  </span>
  <span class="modal-footer__buttons">
    <ng-content>
    </ng-content>
  </span>
</span>
