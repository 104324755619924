import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../troi-modals/modal.service';
import { FooterSettingsService } from '../../../../core/services/footer-settings.service';
import { BaseModalComponent } from '../../../troi-base-modal/baseModal.component';
var SettingsModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsModalComponent, _super);
    function SettingsModalComponent(modalService, settingsService) {
        var _this = _super.call(this, modalService) || this;
        _this.modalService = modalService;
        _this.settingsService = settingsService;
        _this.submitted = false;
        _this.modalObject = _this.modalService.object;
        return _this;
    }
    SettingsModalComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    SettingsModalComponent.prototype.save = function () {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.settingsService.setPerPage(this.form.get('itemsPerPage').value);
        this.close();
    };
    SettingsModalComponent.prototype.initForm = function () {
        this.form = new FormGroup({
            itemsPerPage: new FormControl(this.modalObject.perPage, Validators.required),
        });
    };
    SettingsModalComponent.prototype.isNumberValid = function () {
        return this.form.value.itemsPerPage > 0 && this.form.value.itemsPerPage <= 1000;
    };
    return SettingsModalComponent;
}(BaseModalComponent));
export { SettingsModalComponent };
