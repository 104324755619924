import { AccessRight } from '../../../../core/interfaces/backend.response.interface';
import { Module } from '../enums/module';
var AccountingAccessRightService = /** @class */ (function () {
    function AccountingAccessRightService() {
        var _a;
        this.accessRights = (_a = {},
            _a[Module.ACCOUNT_LIST] = undefined,
            _a[Module.ACCOUNT_GROUP] = undefined,
            _a);
    }
    AccountingAccessRightService.prototype.setAccessRight = function (module, accessRight) {
        this.accessRights[module] = accessRight;
    };
    AccountingAccessRightService.prototype.accessIsSet = function (module) {
        return this.accessRights[module] !== undefined;
    };
    AccountingAccessRightService.prototype.hasReadAccess = function (module) {
        return this.accessRights[module] === AccessRight.READ ||
            this.accessRights[module] === AccessRight.WRITE;
    };
    AccountingAccessRightService.prototype.hasWriteAccess = function (module) {
        return this.accessRights[module] !== undefined &&
            this.accessRights[module] === AccessRight.WRITE;
    };
    return AccountingAccessRightService;
}());
export { AccountingAccessRightService };
