import {
  Directive,
  ElementRef, HostListener,
  Input, OnChanges,
  SimpleChanges, ChangeDetectorRef,
  forwardRef,
  OnInit
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Currency } from './currency';
import { MoneyFormat } from './moneyFormat';
import { Money } from './money';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MoneyDirective),
  multi: true
};

@Directive({
  exportAs: 'money',
  selector: 'money-format, [money-format]',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class MoneyDirective implements OnInit, OnChanges, ControlValueAccessor {
  @Input('money') money: Money;
  @Input('currency') currency: Currency;
  @Input('moneyFormat') moneyFormat: MoneyFormat;

  constructor(private el: ElementRef,
              private cdRef: ChangeDetectorRef
  ) {
  }

  private innerValue: any = '';

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  ngOnInit(): void {
    this.el.nativeElement.value = this.money.editableMoney;
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  @HostListener('focus', ['$event.target'])
  focus() {
    this.el.nativeElement.value = this.money.editableMoney;
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.money.init();
    this.el.nativeElement.value = this.money.editableMoney;
    this.cdRef.detectChanges();
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
