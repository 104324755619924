import {Injectable} from '@angular/core';
import {LanguageTranslationsBackend} from '../interfaces/languageTranslations.backend';
import {StorageService} from '../storage/storage.service';
import {environment} from '../../../environments/environment';
import * as _ from 'lodash';
import {StorageKeys} from '../storage/storage.keys';
import { StorageListener } from '../storage/storage.listener';


@Injectable()
export class LanguagesService {
    constructor(
        public storageService: StorageService,
        private strageListener: StorageListener
        ) {
    }

    public updateLanguageValue(newValue: string, translatedData: LanguageTranslationsBackend): LanguageTranslationsBackend {
        translatedData[this.getDataLanguage()] = newValue;

        return translatedData;
    }

    public updateAllLanguagesWithValue(value: string, translatedData: LanguageTranslationsBackend): LanguageTranslationsBackend {
        _.forEach(Object.keys(translatedData), (language) => {
            translatedData[language] = value;
        });

        return translatedData;
    }

    public getLanguageValue(translatedData: LanguageTranslationsBackend): string {
        return translatedData[this.getDataLanguage()];
    }

    public getDataLanguage(): string {
        const lang = this.storageService.getItem(StorageKeys.DATA_LANG);
        return lang ? lang : environment.defaultLanguage;
    }

    public getLanguage(): string {
        const lang = this.storageService.getItem(StorageKeys.LANG);
        return lang ? lang : environment.defaultLanguage;
    }

    public setDataLanguage(value: string) {
        this.strageListener.store(StorageKeys.DATA_LANG, value);
    }

    public setLanguage(value: string) {
        this.strageListener.store(StorageKeys.LANG, value);
    }

    public getSystemLanguages() {
        return environment.languages;
    }
}
