import { TemplateRef, ViewContainerRef } from '@angular/core';
var RepeatDirective = /** @class */ (function () {
    function RepeatDirective(templateRef, viewContainer) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
    }
    Object.defineProperty(RepeatDirective.prototype, "repeat", {
        set: function (times) {
            for (var i = 0; i < times; i++) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        },
        enumerable: true,
        configurable: true
    });
    return RepeatDirective;
}());
export { RepeatDirective };
