import { Injectable } from '@angular/core';

function _history(): any {
    return window.parent.history;
}

function _window(): any {
    return window.parent;
}

@Injectable()
export class ParentWindowRef {
    get nativeHistory(): any {
        return _history();
    }
    get nativeWindow(): any {
        return _window();
    }
}
