import {Injectable} from '@angular/core';
import {StorageService} from '../storage/storage.service';
import {PaginationSettingsInterface} from '../../shared/troi-footer/interfaces/pagination-settings.interface';
import {Subject} from 'rxjs';
import {UserSettings} from './user-settings';

@Injectable()
export class FooterSettingsService extends UserSettings {
    private actualRoute: string;
    private settingsKey = 'footer-settings';
    public newSettingsDataSet = new Subject<PaginationSettingsInterface>();

    constructor(public storage: StorageService) {
        super(storage);
        this.initLSUserData(this.settingsKey);
    }

    changeRouteKey(key: string) {
        this.actualRoute = key;
    }

    readFromLS(): PaginationSettingsInterface {
        const perPage = this.getSettingForUser(this.settingsKey)[this.actualRoute];

        return perPage ? perPage : this.getDefaultSettings();
    }

    setPerPage(perPage: number) {
        const newSettings = {
            perPage: perPage
        };
        const settings = this.getAllUsersSettings();
        settings[this.getUserId()][this.settingsKey][this.actualRoute] = newSettings;

        this.saveUserSetting(settings);
        this.newSettingsDataSet.next(newSettings);
    }

    private getDefaultSettings(): PaginationSettingsInterface {
        return {
            perPage: 50
        };
    }
}
