import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import * as _ from 'lodash';
import {TroiDropDownCloseService} from '../../troi-dropdown-list/services/troi-dropdown-close.service';
import {FilterElementInterface} from '../filter-element.interface';
import {FilterTypeEnum} from '../filter-type.enum';
import {CurrencyMoneyInterface} from '../../troi-data-listing-filters/filters.interface';

@Component({
    selector: 'troi-filter-modal',
    templateUrl: './troi-filter-modal.component.html',
    styleUrls: ['./troi-filter-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TroiFilterModalComponent implements OnInit, OnDestroy, OnChanges {

    private element: ElementRef;
    public openState = false;
    public _filters: FilterElementInterface[] = [];

    @Input() public forceOpen = false;
    @Input() filterData: FilterElementInterface[];
    @Input() currencyMoneyData: CurrencyMoneyInterface;
    @Output() openChange = new EventEmitter<boolean>();
    @Output() filtersReset = new EventEmitter<boolean>();
    @Output() filtersApplied = new EventEmitter<FilterElementInterface[]>();

    constructor(element: ElementRef, private troiDropDownCloseService: TroiDropDownCloseService) {
        this.element = element;
        this.troiDropDownCloseService.getEmitter().subscribe((event) => {
            const clickedElement = _.find(event.path || event.composedPath(), (path) => {
                return path === this.element.nativeElement || path === this.element.nativeElement.parentElement;
            });
            if (!clickedElement) {
                this.hideModal();
            }
            event.stopPropagation();
        });
    }

    ngOnInit() {
        this._filters = _.cloneDeep(this.filterData);
    }

    hideModal() {
        this.openState = false;
        this.openChange.emit(this.openState);
    }

    ngOnDestroy(): void {
        this._filters = _.cloneDeep(this.filterData);
    }

    ngOnChanges(changes) {
        if (changes.forceOpen) {
            this.openState = this.forceOpen;
        }
    }

    emitFiltersApplied() {
        this.filtersApplied.emit(this._filters);
        this.hideModal();
    }

    emitFiltersReset() {
        this.filtersReset.emit(true);
        this.hideModal();
    }

    isSwitch(type: FilterTypeEnum): boolean {
        return FilterTypeEnum.SWITCH === type;
    }

    isInput(type: FilterTypeEnum): boolean {
        return FilterTypeEnum.INPUT === type;
    }

    isRange(type: FilterTypeEnum): boolean {
        return FilterTypeEnum.RANGE === type;
    }
}
