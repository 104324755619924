import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.overrideRequest(req);

        return next.handle(req);
    }

    private overrideRequest(req: HttpRequest<any>): HttpRequest<any> {
        const headers = req.headers
            .append('Content-Type', 'application/json');

        return req.clone({headers});
    }
}

export let HttpHeaderInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeaderInterceptor,
    multi: true
};
