<div class="troi-input-field"
      [ngClass]="{
        'troi-input-field--disabled': !enabled
      }">
  <input
    [ngClass]="{'troi-input-field__input--invalid': fieldInvalid}"
    class="troi-input-field__input"
    [type]="type"
    (keypress)="filterContent($event)"
    ngDefaultControl>
    <div class="troi-input-field__error" *ngIf="validationEnabled">
        <span *ngIf="fieldInvalid">{{'Form.Error.Required' | translate}}</span>
    </div>
</div>

