/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-progressbar/index.ngfactory";
import * as i3 from "ngx-progressbar";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./core/storage/storage.service";
import * as i9 from "./core/storage/storage.listener";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-progress", [], null, null, null, i2.View_ɵa_0, i2.RenderType_ɵa)), i1.ɵdid(1, 573440, null, 0, i3.ɵa, [i3.NgProgress], { showSpinner: [0, "showSpinner"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "app"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = "blue"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "app"; var currVal_3 = _co.themeColor; _ck(_v, 3, 0, currVal_2, currVal_3); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i7.TranslateService, i8.StorageService, i5.ActivatedRoute, i9.StorageListener], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
