import {
    Component,
    Input,
    forwardRef
} from '@angular/core';

import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor, AbstractControlDirective, AbstractControl
} from '@angular/forms';
import {ErrorTranslationService} from '../../core/language/error.translation.service';

@Component({
    selector: 'troi-input',
    templateUrl: './troi-input.component.html',
    styleUrls: ['./troi-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TroiInputComponent),
        multi: true,
    }]
})
export class TroiInputComponent implements ControlValueAccessor {
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public type = 'text';
    @Input() public size: string;
    @Input() public hideError = false;
    @Input('control') public control: AbstractControlDirective | AbstractControl;
    private innerValue = '';

    private changed = [];
    private touched = [];

    constructor(private errorTranslationService: ErrorTranslationService) {}

    isValid(): boolean {
        return this.control && (this.control.valid || this.control.pristine);
    }

    get value(): string {
        return this.innerValue;
    }

    set value(value: string) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.changed.forEach(f => f(value));
        }
    }

    public showError() {
       return !this.hideError;
    }

    public getErrorMessage() {
        return this.errorTranslationService.getErrorMessages(this.control.errors);
    }

    writeValue(value: string) {
        this.innerValue = value;
    }

    registerOnChange(fn: (value: any) => void) {
        this.changed.push(fn);
    }

    registerOnTouched(fn: () => void) {
        this.touched.push(fn);
    }

    touch() {
        this.touched.forEach(f => f());
    }
}
