import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountingSettingsInterface} from '../interfaces/accounting-settings.interface';
import {BackendResponseInterface} from '../../../../core/interfaces/backend.response.interface';
import {environment} from '../../../../../environments/environment';
import {Routes} from '../enums/routes';

@Injectable()
export class AccountingSettingsNetwork {
    public constructor(public http: HttpClient) {
    }

    public downloadSettings(clientId: number): Observable<BackendResponseInterface<AccountingSettingsInterface>> {
        const params = new HttpParams({
            fromObject: {
                client: clientId ? clientId.toString() : ''
            }
        });
        return this.http.get<BackendResponseInterface<AccountingSettingsInterface>>(
            environment.url + Routes.ACCOUNTING_SETTINGS ,
            {params: params}
            );
    }
}
