import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'troi-checkbox',
  templateUrl: './troi-checkbox.component.html',
  styleUrls: ['./troi-checkbox.component.scss']
})
export class TroiCheckboxComponent implements OnInit, OnChanges {
  _value: boolean;
  @Input() value: boolean;
  @Input() label: string;
  @Input() disabled = false;
  @Input() removeLeftBorder: boolean;
  @Output() checkboxChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
      this._value = this.value;
  }

  public toggleCheckbox() {
      if (!this.disabled) {
          this._value = !this._value;
          this.checkboxChange.emit(this._value);
      }
  }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.value) {
          this._value = this.value;
      }
    }
}
