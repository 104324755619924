import { NgModule } from '@angular/core';
import {AccountingSettingsService} from './common/services/accounting-settings.service';
import {AccountingSettingsNetwork} from './common/networks/accounting-settings.network';
import {AccountsComponent} from './accounts.component';
import {SettingsEmitter} from '../../core/emitters/settings.emitter';
import {RouterModule, Routes} from '@angular/router';
import {HeaderNotificationModule} from '../header-notification/headerNotification.module';
import {SharedModule} from '../../shared/shared.module';
import {AccountingSettingsInterceptorProvider} from './common/interceptors/accounting-settings.interceptor';
import {AccountingAccessRightService} from './common/services/accounting-access-right.service';

const appRoutes: Routes = [
    {
        path: '',
        component: AccountsComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(appRoutes),
        HeaderNotificationModule,
        SharedModule
    ],
    declarations: [
        AccountsComponent
    ],
    entryComponents: [],
    providers: [
        AccountingSettingsService,
        AccountingSettingsNetwork,
        SettingsEmitter,
        AccountingAccessRightService,
        AccountingSettingsInterceptorProvider
    ],
    bootstrap: [],
    exports: []
})
export class AccountsModule {}
