var UserModel = /** @class */ (function () {
    function UserModel(_id, _name, _username, _avatarUrl, _isLogged, _lang) {
        this._id = _id;
        this._name = _name;
        this._username = _username;
        this._avatarUrl = _avatarUrl;
        this._isLogged = _isLogged;
        this._lang = _lang;
    }
    Object.defineProperty(UserModel.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "username", {
        get: function () {
            return this._username;
        },
        set: function (value) {
            this._username = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "avatarUrl", {
        get: function () {
            return this._avatarUrl;
        },
        set: function (value) {
            this._avatarUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "isLogged", {
        get: function () {
            return this._isLogged;
        },
        set: function (value) {
            this._isLogged = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "lang", {
        get: function () {
            return this._lang;
        },
        set: function (value) {
            this._lang = value;
        },
        enumerable: true,
        configurable: true
    });
    return UserModel;
}());
export { UserModel };
