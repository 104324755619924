import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'string',
    templateUrl: './string.component.html',
    styleUrls: ['./string.component.scss']
})
export class StringComponent {

    @Input()
    content: string;
}
