import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './not-found.component';
import {AppRoutingModule} from './app-routing.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderNotificationModule} from './modules/header-notification/headerNotification.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {TroiHttpLoaderFactory} from './core/language/troi.translate.loader';
import 'rxjs/Rx';
import {SharedModule} from './shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from './core/core.module';
import {NgProgressInterceptor, NgProgressModule} from 'ngx-progressbar';
import {CommonModule} from '@angular/common';
import { StorageListener } from './core/storage/storage.listener';
import {ParentWindowRef} from './core/services/parent-window-ref.service';
import {AccountsModule} from './modules/accounts/accounts.module';

@NgModule({
    imports: [
        NgbModule,
        TranslateModule.forRoot(TroiHttpLoaderFactory),
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        HeaderNotificationModule,
        FormsModule,
        NgProgressModule,
        AccountsModule,
        ReactiveFormsModule,
        CommonModule,
        CoreModule,
    ],
    declarations: [
        AppComponent,
        PageNotFoundComponent
    ],
    bootstrap: [
        AppComponent,
    ],
    providers: [
        StorageListener,
        ParentWindowRef,
        {provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true}
    ],
    exports: [
        BrowserModule
    ]
})
export class AppModule {
    constructor(router: Router) {
    }
}
