import * as tslib_1 from "tslib";
import { EventStrategies } from './event-strategies.enum';
import { BaseStrategy } from './base.strategy';
var CLickStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(CLickStrategy, _super);
    function CLickStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CLickStrategy.prototype.support = function (type) {
        return type === EventStrategies.CLICK;
    };
    CLickStrategy.prototype.handle = function (element) {
        var event = new MouseEvent('click', { bubbles: true });
        this.invokeElementMethod(element.nativeElement, 'dispatchEvent', [event]);
    };
    return CLickStrategy;
}(BaseStrategy));
export { CLickStrategy };
