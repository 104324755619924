import * as tslib_1 from "tslib";
import { EventStrategies } from './event-strategies.enum';
import { BaseStrategy } from './base.strategy';
var DblCLickStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(DblCLickStrategy, _super);
    function DblCLickStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DblCLickStrategy.prototype.support = function (type) {
        return type === EventStrategies.DBLCLICK;
    };
    DblCLickStrategy.prototype.handle = function (element) {
        var event = new MouseEvent('dblclick', { bubbles: true });
        this.invokeElementMethod(element.nativeElement, 'dispatchEvent', [event]);
    };
    return DblCLickStrategy;
}(BaseStrategy));
export { DblCLickStrategy };
