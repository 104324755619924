/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-input-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "./troi-input-field.component";
var styles_TroiInputFieldComponent = [i0.styles];
var RenderType_TroiInputFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiInputFieldComponent, data: {} });
export { RenderType_TroiInputFieldComponent as RenderType_TroiInputFieldComponent };
function View_TroiInputFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Form.Error.Required")); _ck(_v, 1, 0, currVal_0); }); }
function View_TroiInputFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "troi-input-field__error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiInputFieldComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fieldInvalid; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TroiInputFieldComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { valueAccessor: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "troi-input-field"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "troi-input-field--disabled": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "input", [["class", "troi-input-field__input"], ["ngDefaultControl", ""]], [[8, "type", 0]], [[null, "keypress"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keypress" === en)) {
        var pd_4 = (_co.filterContent($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(6, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "troi-input-field__input--invalid": 0 }), i1.ɵdid(8, 16384, [[1, 4]], 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiInputFieldComponent_1)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "troi-input-field"; var currVal_1 = _ck(_v, 3, 0, !_co.enabled); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = "troi-input-field__input"; var currVal_4 = _ck(_v, 7, 0, _co.fieldInvalid); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.validationEnabled; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.type; _ck(_v, 4, 0, currVal_2); }); }
export function View_TroiInputFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "troi-input-field", [], null, [[null, "focusout"]], function (_v, en, $event) { var ad = true; if (("focusout" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).focusout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TroiInputFieldComponent_0, RenderType_TroiInputFieldComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.TroiInputFieldComponent]), i1.ɵdid(2, 638976, null, 0, i5.TroiInputFieldComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TroiInputFieldComponentNgFactory = i1.ɵccf("troi-input-field", i5.TroiInputFieldComponent, View_TroiInputFieldComponent_Host_0, { value: "value", enabled: "enabled", fieldInvalid: "fieldInvalid", validationEnabled: "validationEnabled", numbersOnly: "numbersOnly", integersOnly: "integersOnly", type: "type" }, {}, []);
export { TroiInputFieldComponentNgFactory as TroiInputFieldComponentNgFactory };
