import {
    Component, Input
} from '@angular/core';
import {ModalService} from '../troi-modals/modal.service';

@Component({
    selector: 'troi-modal-header',
    templateUrl: './troi-modal-header.component.html',
    styleUrls: ['./troi-modal-header.component.scss']
})

export class TroiModalHeaderComponent {
    @Input() public noCloseButton = false;

    constructor(
        public modalService: ModalService
    ) {
    }

    public close() {
        this.modalService.destroy();
    }
}
