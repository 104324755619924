import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TroiDropDownCloseService} from '../../troi-dropdown-list/services/troi-dropdown-close.service';
import * as _ from 'lodash';
import {ColumnSelectInterface} from '../column-select.interface';

@Component({
  selector: 'troi-columns-list',
  templateUrl: './troi-columns-list.component.html',
  styleUrls: ['./troi-columns-list.component.scss'],
})
export class TroiColumnsListComponent implements OnInit, OnChanges {

    private element: ElementRef;
    public openState = false;
    public _columns: ColumnSelectInterface[] = [];

    @Input() public forceOpen = false;
    @Input() columnList: ColumnSelectInterface[] = [];
    @Output() openChange = new EventEmitter<boolean>();
    @Output() columnsSaved = new EventEmitter<ColumnSelectInterface[]>();

    constructor(element: ElementRef, private troiDropDownCloseService: TroiDropDownCloseService) {
        this.element = element;
        this.troiDropDownCloseService.getEmitter().subscribe((event) => {
            const clickedElement = _.find(event.path || event.composedPath(), (path) => {
                return path === this.element.nativeElement || path === this.element.nativeElement.parentElement;
            });
            if (!clickedElement) {
                this.hideModal();
            }
            event.stopPropagation();
        });
    }

    ngOnInit() {
        this._columns = _.cloneDeep(this.columnList);
    }

    hideModal() {
        this.openState = false;
        this.openChange.emit(this.openState);
    }

    ngOnChanges(changes) {
        if (changes.forceOpen) {
            this.openState = this.forceOpen;

        }
    }

    onCancel() {
        this._columns = this.columnList;
        this.hideModal();
    }

    onSave() {
        this.columnsSaved.emit(this._columns);
        this.hideModal();
    }
}
