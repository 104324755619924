/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-modal-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../troi-icon/troi-icon.component.ngfactory";
import * as i3 from "../troi-icon/troi-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "./troi-modal-header.component";
import * as i6 from "../troi-modals/modal.service";
var styles_TroiModalHeaderComponent = [i0.styles];
var RenderType_TroiModalHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiModalHeaderComponent, data: {} });
export { RenderType_TroiModalHeaderComponent as RenderType_TroiModalHeaderComponent };
function View_TroiModalHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "troi-icon", [["icon", "icon-close"]], null, null, null, i2.View_TroiIconComponent_0, i2.RenderType_TroiIconComponent)), i1.ɵdid(2, 114688, null, 0, i3.TroiIconComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "icon-close"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TroiModalHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-header__name"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiModalHeaderComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.noCloseButton; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_TroiModalHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-modal-header", [], null, null, null, View_TroiModalHeaderComponent_0, RenderType_TroiModalHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.TroiModalHeaderComponent, [i6.ModalService], null, null)], null, null); }
var TroiModalHeaderComponentNgFactory = i1.ɵccf("troi-modal-header", i5.TroiModalHeaderComponent, View_TroiModalHeaderComponent_Host_0, { noCloseButton: "noCloseButton" }, {}, ["*"]);
export { TroiModalHeaderComponentNgFactory as TroiModalHeaderComponentNgFactory };
