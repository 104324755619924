import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterElementInterface} from './filter-element.interface';
import {CurrencyMoneyInterface} from '../troi-data-listing-filters/filters.interface';

@Component({
    selector: 'troi-filter-with-modal',
    templateUrl: './troi-filter-with-modal.component.html',
    styleUrls: ['./troi-filter-with-modal.component.scss']
})
export class TroiFilterWithModalComponent implements OnInit {

    open = false;
    forceOpen = false;

    @Input() filterData: FilterElementInterface[];
    @Input() currencyMoneyData: CurrencyMoneyInterface;
    @Output() filtersApplied = new EventEmitter<FilterElementInterface[]>();
    @Output() filtersReset = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {

    }

    onOpen(state) {
        this.open = state;
    }

    toggleOpen() {
        this.open = !this.open;
        this.forceOpen = this.open;
    }

    emitFiltersApplied(filters: FilterElementInterface[]) {
        this.filtersApplied.emit(filters);
    }

    emitFiltersReset() {
        this.filtersReset.emit(true);
    }
}
