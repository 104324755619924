import {
    Component,
    Input,
    ElementRef,
    forwardRef, Output, EventEmitter, OnChanges, HostListener, OnInit, ViewChild
} from '@angular/core';

import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor
} from '@angular/forms';

import {TroiDropdownListModel} from '../troi-dropdown-list/models/troi-dropdown-list.model';
import * as _ from 'lodash';
import {TroiDropdownListComponent} from '../troi-dropdown-list/troi-dropdown-list.component';

const noop = () => {
};

export const TROI_DROPDOWN_SELECT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TroiDropdownSelectComponent),
    multi: true
};

@Component({
    selector: 'troi-dropdown-select',
    templateUrl: './troi-dropdown-select.component.html',
    styleUrls: ['./troi-dropdown-select.component.scss'],
    providers: [TROI_DROPDOWN_SELECT_CONTROL_VALUE_ACCESSOR]
})

export class TroiDropdownSelectComponent implements ControlValueAccessor, OnChanges, OnInit {
    @ViewChild(TroiDropdownListComponent) optionList: TroiDropdownListComponent;
    constructor(element: ElementRef) {
        this.element = element;
    }
    @Input() public options: Array<TroiDropdownListModel>;
    @Input() public static: Boolean = false;
    @Input() public noBorder: Boolean = false;
    @Input() public fullWidth: Boolean = false;
    @Input() public size: string;
    @Input() public forceOpen: Boolean = false;
    @Input() public top = false;
    @Input() public enabled = true;
    @Input() public clearButton = false;
    @Input() public noMinWith = false;
    @Input() public width = '100%';
    @Input() public toRight = false;
    @Input() public placeholder = '';
    @Input() public initValue;
    @Input() public search: Boolean = false;
    @Input() public disable = false;
    @Input() public fieldInvalid = false;
    @Input() public validationEnabled = false;
    @Output() selected = new EventEmitter<object>();

    private element: ElementRef;
    private innerValue: any = '';
    public open: Boolean;

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    ngOnInit(): void {
    }

    onOpen(state) {
        this.open = state;
    }

    onSelect(event) {
        this.element.nativeElement.focus();
        this.value = event.value;
        this.selected.emit(event.value);
        this.forceOpen = false;
    }

    get label() {
        if (this.options && this.options.filter(option => (option.value === this.innerValue))[0]) {
            return this.options.filter(option => (option.value === this.innerValue))[0].label;
        }
    }

    onBlur() {
        this.onTouchedCallback();
    }

    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    ngOnChanges(changes) {
        if (changes.initValue && !_.isUndefined(changes.initValue.currentValue)) {
            this.value = changes.initValue.currentValue;
        }
        if (changes.forceOpen) {
            this.open = this.forceOpen;
        }
    }

    clearValue(event) {
        event.stopPropagation();
        this.value = null;
        this.optionList.hideList();
        this.selected.emit(null);
    }

    @HostListener('keydown.space', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.forceOpen = !this.forceOpen;
        event.preventDefault();
        event.stopPropagation();
    }
}
