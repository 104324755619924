import 'rxjs/Rx';
import {NgModule} from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';
import {TroiSwitchComponent} from './troi-switch/troi-switch.component';
import {TroiIconComponent} from './troi-icon/troi-icon.component';
import {TroiInlineEditComponent} from './troi-inline-edit/troi-inline-edit.component';
import {TroiDropdownListComponent} from './troi-dropdown-list/troi-dropdown-list.component';
import {TroiDropdownOptionComponent} from './troi-dropdown-option/troi-dropdown-option.component';
import {TroiDropdownSelectComponent} from './troi-dropdown-select/troi-dropdown-select.component';
import {TroiInputComponent} from './troi-input/troi-input.component';
import {TroiModalHeaderComponent} from './troi-modal-header/troi-modal-header.component';
import {TroiModalContentComponent} from './troi-modal-content/troi-modal-content.component';
import {TroiModalFooterComponent} from './troi-modal-footer/troi-modal-footer.component';
import {TroiModalRowComponent} from './troi-modal-row/troi-modal-row.component';
import {TroiModalSectionComponent} from './troi-modal-section/troi-modal-section.component';
import {TroiBtnComponent} from './troi-btn/troi-btn.component';
import {TroiInputFieldComponent} from './troi-input-field/troi-input-field.component';
import {TroiTextareaComponent} from './troi-textarea/troi-textarea.component';
import {TroiFilterComponent} from './troi-filter/troi-filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {FocusDirective} from './focus/focus.directive';
import {MoneyDirective} from './troi-money/money.directive';
import { TroiDropDownCloseService } from './troi-dropdown-list/services/troi-dropdown-close.service';
import {OriginalValueTooltipComponent} from './troi-original-value-tooltip/original-value-tooltip.component';
import {TranslateModule} from '@ngx-translate/core';
import {ObjectChangesService} from './troi-original-value-tooltip/object-changes.service';
import { TroiInlineTabDirective } from './troi-inline-edit/troi-inline-tab.directive';
import { TroiInlineTabService } from './troi-inline-edit/services/troi-inline-tab.service';
import { VirtualEventStrategiesFactory } from './troi-inline-edit/eventStrategies/virtual-event-strategies.factory';
import {RepeatDirective} from './repeat/repeat.directive';
import {TroiLoadingComponent} from './troi-loading/troi-loading.component';
import {TroiDataListingTabsComponent} from './troi-data-listing-tabs/troi-data-listing-tabs.component';
import {RouterModule} from '@angular/router';
import {TroiDataListingFiltersComponent} from './troi-data-listing-filters/troi-data-listing-filters.component';
import { TroiFilterWithModalComponent } from './troi-filter-with-modal/troi-filter-with-modal.component';
import {TroiFilterModalComponent} from './troi-filter-with-modal/troi-filter-modal/troi-filter-modal.component';
import { TroiBreadcrumbsComponent } from './troi-breadcrumbs/troi-breadcrumbs.component';
import { TroiColumnsSelectorComponent } from './troi-columns-selector/troi-columns-selector.component';
import { TroiColumnsListComponent } from './troi-columns-selector/troi-columns-list/troi-columns-list.component';
import { TroiTableComponent } from './troi-table/troi-table.component';
import { TroiTableRowComponent } from './troi-table/row/row.component';
import { TroiTableColumnComponent } from './troi-table/row/column/column.component';
import { StringComponent } from './troi-table/string/string.component';
import {DomService} from './troi-modals/dom.service';
import {ComponentFactoryService} from './troi-table/services/component-factory.service';
import {TroiCheckboxComponent} from './troi-checkbox/troi-checkbox.component';
import {FooterNetworkService} from './troi-footer/network/footer.network.service';
import {FooterService} from './troi-footer/services/footer.service';
import {LegacyAjaxServerNetworkService} from '../core/network/legacyAjaxServer.network.service';
import {FooterComponent} from './troi-footer/footer.component';
import { TroiFilterChipsComponent } from './troi-filter-chips/troi-filter-chips.component';
import {TroiRangeComponent} from './troi-range/troi-range.component';
import {TroiUploadIconComponent} from './troi-upload-icon/troi-upload-icon.component';
import { SettingsModalComponent } from './troi-footer/modal/settings-modal/settings-modal.component';
import {ModalService} from './troi-modals/modal.service';
import {TroiRadioComponent} from './troi-radio/troi-radio.component';
import { TroiPaginatorComponent } from './troi-paginator/troi-paginator.component';

const COMPONENTS = [
    TroiSwitchComponent,
    TroiIconComponent,
    TroiUploadIconComponent,
    TroiInputComponent,
    TroiInlineEditComponent,
    TroiDropdownListComponent,
    TroiDropdownOptionComponent,
    TroiDropdownSelectComponent,
    TroiModalHeaderComponent,
    TroiModalContentComponent,
    TroiModalFooterComponent,
    TroiModalRowComponent,
    TroiModalSectionComponent,
    TroiBtnComponent,
    TroiInputFieldComponent,
    TroiTextareaComponent,
    TroiFilterComponent,
    FocusDirective,
    MoneyDirective,
    TroiInlineTabDirective,
    OriginalValueTooltipComponent,
    TroiLoadingComponent,
    RepeatDirective,
    TroiDataListingTabsComponent,
    TroiDataListingFiltersComponent,
    TroiFilterModalComponent,
    TroiBreadcrumbsComponent,
    TroiFilterWithModalComponent,
    TroiColumnsSelectorComponent,
    TroiColumnsListComponent,
    TroiTableComponent,
    TroiTableRowComponent,
    TroiTableColumnComponent,
    TroiRangeComponent,
    StringComponent,
    TroiFilterChipsComponent,
    TroiRadioComponent,
    TroiCheckboxComponent,
    TroiPaginatorComponent
];

@NgModule({
    imports: [
        CommonModule,
        ClickOutsideModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        ...COMPONENTS,
        FooterComponent,
        TroiFilterChipsComponent,
        SettingsModalComponent
    ],
    bootstrap: [],
    providers: [
        TroiDropDownCloseService,
        ObjectChangesService,
        TroiInlineTabService,
        DomService,
        VirtualEventStrategiesFactory,
        ComponentFactoryService,
        FooterNetworkService,
        FooterService,
        LegacyAjaxServerNetworkService,
        ModalService
    ],
    entryComponents: [
        StringComponent,
        TroiInlineEditComponent,
        SettingsModalComponent
    ],
    exports: [
        ...COMPONENTS,
        TranslateModule,
        FooterComponent
    ]
})
export class SharedModule {}
