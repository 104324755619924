import {TroiDropdownListModel} from '../troi-dropdown-list/models/troi-dropdown-list.model';
import {CurrencyMoneyInterface, FiltersInterface} from './filters.interface';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ColumnSelectInterface} from '../troi-columns-selector/column-select.interface';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterElementInterface} from '../troi-filter-with-modal/filter-element.interface';
import {FilterChipsInterface} from '../troi-filter-chips/filter-chips.interface';
import * as _ from 'lodash';
import {FilterTypeEnum} from '../troi-filter-with-modal/filter-type.enum';

@Component({
  selector: 'troi-data-listing-filters',
  templateUrl: './troi-data-listing-filters.component.html',
  styleUrls: ['./troi-data-listing-filters.component.scss']
})
export class TroiDataListingFiltersComponent implements OnInit {

    filters: FiltersInterface = {
        search: '',
        dropdownFirst: null,
        dropdownSecond: null,
        filters: null,
        currentPage: 1,
        pageSize: 0
    };
    searchInput = new Subject<string>();

    @Input() firstDropdown: Array<TroiDropdownListModel> = [];
    @Input() secondDropdown: Array<TroiDropdownListModel> = [];
    @Input() columnList: ColumnSelectInterface[] = [];
    @Input() filtersValues: FiltersInterface;
    @Input() currencyMoneyData: CurrencyMoneyInterface;

    @Output() filtersChanged = new EventEmitter<FiltersInterface>();
    @Output() firstDropdownChanged = new EventEmitter<FiltersInterface>();
    @Output() secondDropdownChanged = new EventEmitter<FiltersInterface>();
    @Output() columnsChanged = new EventEmitter<ColumnSelectInterface[]>();
    @Output() filtersReset = new EventEmitter<boolean>();

    constructor() {
        this.searchInput.pipe(
            debounceTime(1000),
            distinctUntilChanged()
        ).subscribe(() => {
            if (this.filters.search.length > 2 || this.filters.search.length === 0) {
                this.emitFiltersChanged();
            }
        });
    }

    ngOnInit() {
        this.filters = this.filtersValues;
    }

    columnDisabled(): boolean {
        return this.columnList.length === 0;
    }

    searchActive(): boolean {
        return this.filters.search.length > 0;
    }

    emitFiltersChanged() {
        if (this.filters.search.length < 3) {
            this.filters.search = '';
        }
        this.filtersChanged.emit(this.filters);
    }

    emitFiltersReset() {
        this.filtersReset.emit(true);
    }

    emitFirstDropdownChanged() {
        this.firstDropdownChanged.emit(this.filters);
    }

    emitSecondDropdownChanged() {
        this.secondDropdownChanged.emit(this.filters);
    }

    filtersApplied(filtersChanged: FilterElementInterface[]) {
        this.filters.filters = filtersChanged;
        this.emitFiltersChanged();
    }

    prepareFilterChips(): FilterChipsInterface[] {
        const chips: FilterChipsInterface[] = [];

        _.forEach(this.filters.filters, (singleFilter: FilterElementInterface) => {
            if (singleFilter.value instanceof Array) {
                _.forEach(singleFilter.value, (value, index) => {
                    if (!_.isNull(value)
                        && (_.isNull(singleFilter.defaultValue[index])
                            || value.formattedValue !== singleFilter.defaultValue[index].formattedValue)
                    ) {
                        chips.push({
                            id: singleFilter.formName,
                            label: singleFilter.chips[index].label,
                            value: value.formattedValue,
                            index: index
                        });
                    }
                });
            } else {
                if (singleFilter.defaultValue !== singleFilter.value) {
                    chips.push({
                        id: singleFilter.formName,
                        label: singleFilter.chips[0].label,
                        value: this.getValueLabelForChips(singleFilter)
                    });
                }
            }
        });
        return chips;
    }

    private getValueLabelForChips(filter: FilterElementInterface): string {
        switch (filter.type) {
            case FilterTypeEnum.RANGE:
                return filter.value;
            case FilterTypeEnum.SWITCH:
                return filter.chips[0].value;
            case FilterTypeEnum.INPUT:
                return filter.value;
        }
    }

    onFilterChipsClosed(chip: FilterChipsInterface) {
       const singleFilter = _.find(this.filters.filters, ['formName', chip.id]);

       if (singleFilter) {
           if (!_.isUndefined(chip.index)) {
               singleFilter.value[chip.index] = singleFilter.defaultValue[chip.index];
           } else {
               singleFilter.value = singleFilter.defaultValue;
           }

           this.emitFiltersChanged();
       }
    }

    onColumnChanged(columns: ColumnSelectInterface[]) {
        this.columnsChanged.emit(columns);
    }
}
