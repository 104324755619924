<span class="troi-dropdown-select-wrapper"
  [ngClass]="{
    'troi-dropdown-select-wrapper--disabled': !enabled
  }">
  <troi-dropdown-list #optionList
    [forceOpen]="forceOpen"
    (openChange)="onOpen($event)"
    [options]="options"
    [value]="value"
    [static]="static"
    [top]="top"
    [noMinWith]="noMinWith"
    [width]="width"
    [disable]="disable"
    [search]="search"
    (selectedEmitter)="onSelect($event)">
    <span class="troi-dropdown-select"
      [ngClass]="{'troi-dropdown-select--no-border': noBorder,
      'troi-dropdown-select--full-width': fullWidth,
      'troi-dropdown-select--invalid' : fieldInvalid,
      'troi-dropdown-select--double-height': validationEnabled
      }">
      <span class="troi-dropdown-select__label"
            [ngClass]="{'toRight' : toRight}"
            [ngStyle]="{'font-size': size}">
        <span class="troi-dropdown-select__label-wrapper" *ngIf="label">
          {{ label }}
        </span>
        <span class="troi-dropdown-select__label-wrapper" *ngIf="!label && placeholder">
          {{ placeholder }}
        </span>
      </span>
        <troi-icon *ngIf="clearButton && value !== null"
                   class="troi-dropdown-select__clear-button"
                   [small]="true"
                   icon="icon-close"
                   (click)="clearValue($event)"></troi-icon>
      <span class="troi-dropdown-select__icon">
        <troi-icon
          *ngIf="!open"
          icon="icon-bold-arrow-down"></troi-icon>
        <troi-icon
          *ngIf="open"
          icon="icon-bold-arrow-up"></troi-icon>
      </span>
    </span>
    <div class="troi-dropdown-select__error" *ngIf="validationEnabled">
      <span *ngIf="fieldInvalid">{{'Form.Error.Required' | translate}}</span>
    </div>
    <span options>
      <ng-content select="[options]">
      </ng-content>
    </span>
  </troi-dropdown-list>
</span>
