import { OnInit } from '@angular/core';
import { AbstractControl, AbstractControlDirective, ControlValueAccessor } from '@angular/forms';
import * as _ from 'lodash';
var TroiSwitchComponent = /** @class */ (function () {
    function TroiSwitchComponent() {
        this.label = '';
        this.scrollTo = false;
        this._value = false;
    }
    TroiSwitchComponent.prototype.ngOnInit = function () {
        this.small = !_.isUndefined(this.small);
    };
    Object.defineProperty(TroiSwitchComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (val) {
            this._value = val;
            this.onChangeCallback(val);
            this.onTouchedCallback();
        },
        enumerable: true,
        configurable: true
    });
    TroiSwitchComponent.prototype.writeValue = function (obj) {
        this._value = obj;
    };
    TroiSwitchComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    TroiSwitchComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    TroiSwitchComponent.prototype.switch = function () {
        this.value = !this._value;
    };
    TroiSwitchComponent.prototype.scrollToView = function (element) {
        if (this.scrollTo) {
            element.target.scrollIntoView();
        }
    };
    return TroiSwitchComponent;
}());
export { TroiSwitchComponent };
