import {Component, OnInit} from '@angular/core';
import {AccountingSettingsService} from './common/services/accounting-settings.service';
import {BasicFiltersService} from '../../core/services/basic-filters.service';

@Component({
  selector: 'accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent {
    constructor(
        public settingsService: AccountingSettingsService,
        public basicFiltersService: BasicFiltersService
    ) {
    }
}
