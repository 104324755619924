import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterChipsInterface} from './filter-chips.interface';

@Component({
  selector: 'troi-filter-chips',
  templateUrl: './troi-filter-chips.component.html',
  styleUrls: ['./troi-filter-chips.component.scss']
})
export class TroiFilterChipsComponent implements OnInit {

  @Input() chips: FilterChipsInterface[] = [];
  @Output() filterClosed = new EventEmitter<FilterChipsInterface>();

  constructor() { }

  ngOnInit() {}

  emitFilterClosed(filterChip: FilterChipsInterface) {
      this.filterClosed.emit(filterChip);
  }
}
