import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticationService} from '../security/authentication.service';
import {HttpHeaderInterceptorProvider} from './interceptiors/http.interceptor';
import {HttpErrorInterceptorProvider} from './interceptiors/httpError.interceptor';
import {UserService} from './user/user.service';
import {ErrorTranslationService} from './language/error.translation.service';
import {StorageNotificationService} from './notifications/storageNotification.service';
import {HttpUrlInterceptorProvider} from './interceptiors/httpUrl.interceptor';
import {StorageService} from './storage/storage.service';
import {CodesTranslationService} from './codes/codes.translation.service';
import {BackendErrorTranslationService} from './services/backendError.translation.service';
import { ColorSwitcherService } from './colorSwitcher/colorSwitcher.service';
import {LanguagesService} from './services/languages.service';
import {BasicFiltersService} from './services/basic-filters.service';
import {BasicFiltersNetwork} from './network/basic-filters.network';
import {FooterSettingsService} from './services/footer-settings.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [

    ],
    bootstrap: [

    ],
    providers: [
        AuthenticationService,
        ErrorTranslationService,
        StorageService,
        HttpUrlInterceptorProvider,
        HttpHeaderInterceptorProvider,
        HttpErrorInterceptorProvider,
        UserService,
        StorageNotificationService,
        CodesTranslationService,
        BackendErrorTranslationService,
        ColorSwitcherService,
        LanguagesService,
        BasicFiltersService,
        BasicFiltersNetwork,
        FooterSettingsService
    ],
    exports: [
    ]
})
export class CoreModule {
}
