import {
    Component, EventEmitter,
    Input, OnInit,
    Output,
} from '@angular/core';
import {Money} from '../troi-money/money';
import {Currency} from '../troi-money/currency';
import * as _ from 'lodash';

@Component({
  selector: 'troi-range',
  templateUrl: './troi-range.component.html',
  styleUrls: ['./troi-range.component.scss']
})

export class TroiRangeComponent implements OnInit {
    _rangeFrom: Money;
    _rangeTo: Money;
    rangeToIsNull = true;

    @Input() public rangeFrom: any;
    @Input() public rangeTo: any;
    @Input() public disabled = false;
    @Input() public currency: Currency;
    @Input() public decimalPlaces: number;
    @Output() public rangeChanged = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
        this._rangeFrom = this.rangeFrom;

        if (_.isNull(this.rangeTo)) {
            this.rangeToIsNull = true;
        } else {
            this.rangeToIsNull = false;
            this._rangeTo = this.rangeTo;
        }
    }

    public updateRange(value, index: number) {
        if (value === '' && index === 0) {
            value = '0';
        }
        if (index === 0) {
            this._rangeFrom = this.convertToMoney(value);
        } else if (index === 1) {
            if (!value) {
                this.rangeToIsNull = true;
            } else {
                this.rangeToIsNull = false;
                this._rangeTo = this.convertToMoney(value);
            }
        }

        const range = [this._rangeFrom, this.rangeToIsNull ? null : this._rangeTo];
        this.rangeChanged.emit(range);
    }

    convertToMoney(value): Money {
        return new Money(
            value.replace(this.currency.format.decimalMark, '.'),
            this.currency,
            this.decimalPlaces
        );
    }
}
