import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'troi-radio',
  templateUrl: './troi-radio.component.html',
  styleUrls: ['./troi-radio.component.scss']
})
export class TroiRadioComponent {
  @Input() name: string;
  @Input() value = '';
  @Input() required = false;
  @Input() label: string;
  @Input() disabled = false;
  @Input() checked = false;
  @Output() change = new EventEmitter();

  constructor() { }

  public onChange(event: Event) {
      event.stopPropagation();
      this.checked = (event.target as HTMLInputElement).checked;
      this.change.emit(this.checked);
  }
}
