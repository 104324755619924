/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-inline-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng-click-outside/lib/click-outside.directive";
import * as i4 from "../focus/focus.directive";
import * as i5 from "./troi-inline-edit.component";
var styles_TroiInlineEditComponent = [i0.styles];
var RenderType_TroiInlineEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiInlineEditComponent, data: {} });
export { RenderType_TroiInlineEditComponent as RenderType_TroiInlineEditComponent };
function View_TroiInlineEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "troi-inline-edit__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 1, 0, currVal_0); }); }
function View_TroiInlineEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "troi-inline-edit__edit"], ["cssSelector", "input, select"], ["focus-directive", ""]], null, [[null, "clickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOutside" === en)) {
        var pd_0 = (_co.onClickOutside() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "troi-inline-edit__edit--width-auto": 0 }), i1.ɵdid(3, 737280, null, 0, i3.ClickOutsideDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], { clickOutsideEvents: [0, "clickOutsideEvents"] }, { clickOutside: "clickOutside" }), i1.ɵdid(4, 81920, null, 0, i4.FocusDirective, [i1.ElementRef, i1.NgZone], { cssSelector: [0, "cssSelector"], withSelection: [1, "withSelection"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "troi-inline-edit__edit"; var currVal_1 = _ck(_v, 2, 0, _co.widthAuto); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "click,touchstart"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "input, select"; var currVal_4 = true; _ck(_v, 4, 0, currVal_3, currVal_4); }, null); }
export function View_TroiInlineEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "troi-inline-edit"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "toRight": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiInlineEditComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiInlineEditComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "troi-inline-edit"; var currVal_1 = _ck(_v, 2, 0, _co.toRight); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_co.editMode; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.editMode; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_TroiInlineEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-inline-edit", [], null, [[null, "click"], [null, "keydown.enter"], [null, "dblclick"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClickInside($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("dblclick" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onDoubleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_TroiInlineEditComponent_0, RenderType_TroiInlineEditComponent)), i1.ɵdid(1, 573440, null, 0, i5.TroiInlineEditComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var TroiInlineEditComponentNgFactory = i1.ɵccf("troi-inline-edit", i5.TroiInlineEditComponent, View_TroiInlineEditComponent_Host_0, { value: "value", ngContentModel: "ngContentModel", focus: "focus", toRight: "toRight", widthAuto: "widthAuto" }, { editModeEmitter: "editModeEmitter", enterEmitter: "enterEmitter" }, ["*"]);
export { TroiInlineEditComponentNgFactory as TroiInlineEditComponentNgFactory };
