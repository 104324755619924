import {Injectable} from '@angular/core';
import {
    AccountGroupDropdownInterface,
    AccountingSettingsInterface,
    AccountTypeInterface, TaxRateInterface, YearInterface
} from '../interfaces/accounting-settings.interface';
import {StorageService} from '../../../../core/storage/storage.service';
import {StorageKeys} from '../../../../core/storage/storage.keys';
import {AccountingSettingsNetwork} from '../networks/accounting-settings.network';
import {TroiDropdownListModel} from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import * as _ from 'lodash';
import {LanguagesService} from '../../../../core/services/languages.service';
import {SettingsEmitter} from '../../../../core/emitters/settings.emitter';
import {Currency} from '../../../../shared/troi-money/currency';
import {MoneyFormat} from '../../../../shared/troi-money/moneyFormat';
import {CurrencyInterface} from '../../../../core/interfaces/currency.interface';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class AccountingSettingsService {
    public settings: AccountingSettingsInterface;
    public systemCurrency: Currency;

    constructor(
        private storageService: StorageService,
        public network: AccountingSettingsNetwork,
        private languagesService: LanguagesService,
        private settingsEmitter: SettingsEmitter
        ) {
        this.settings = this.getSettingsFromLS();
    }

    prepareSettings(clientId: number): Observable<any> {
        this.settings = this.getSettingsFromLS();
        if (!this.settings || this.settings.client !== clientId) {
            return this.downloadSettings(clientId);
        } else {
            return this.prepareSettingCurrency();
        }
    }

    saveSettingsToLS(settings: AccountingSettingsInterface) {
        this.storageService.setItem(StorageKeys.ACCOUNTING_SETTINGS, settings);
    }

    public buildAccountTypeList(): Array<TroiDropdownListModel> {
        const list = [];
        if (!this.settings) {
            return [];
        }

        _.forEach(this.settings.dropdowns.accountTypes, (element: AccountTypeInterface) => {
            list.push({
                label: this.languagesService.getLanguageValue(element.name),
                value: element.id,
                active: true
            });
        });
        return list;
    }

    public buildAccountGroupList(): Array<TroiDropdownListModel> {
        const list = [];
        if (!this.settings) {
            return [];
        }
        _.forEach(this.settings.dropdowns.accountGroups, (element: AccountGroupDropdownInterface) => {
            list.push({
                label: element.description,
                value: element.id,
                active: true
            });
        });

        return list;
    }

    public buildTaxKeyList(): Array<TroiDropdownListModel> {
        const list = [];
        if (!this.settings) {
            return [];
        }

        _.forEach(this.settings.dropdowns.taxRates, (element: TaxRateInterface) => {
            list.push({
                label: this.languagesService.getLanguageValue(element.name),
                value: element.id,
                active: true
            });
        });
        return list;
    }

    public buildBookingYears(): Array<TroiDropdownListModel> {
        const list = [];
        if (!this.settings) {
            return [];
        }

        _.forEach(this.settings.dropdowns.bookingYears, (element: YearInterface) => {
            list.push({
                label: element.year,
                value: element.year,
                active: true
            });
        });
        return list;
    }

    public getSettingsFromLS(): AccountingSettingsInterface {
        return this.storageService.getItem(StorageKeys.ACCOUNTING_SETTINGS);
    }

    private downloadSettings(clientId: number) {
        const subject = new Subject();
        this.network.downloadSettings(clientId).subscribe((result) => {
           this.settings = result.data;
           this.saveSettingsToLS(this.settings);
           this.prepareSettingCurrency();
           subject.next();
        });

        return subject.asObservable();
    }

    private buildCurrency(currencyData: CurrencyInterface, client: number): Currency {
        return new Currency(
            client,
            currencyData.id,
            currencyData.symbol,
            currencyData.name,
            new MoneyFormat(
                currencyData.decimalMark,
                currencyData.thousandsMark,
                currencyData.isSymbolBehind,
                currencyData.precision
            ),
            currencyData.exchangeRate,
            currencyData.isSystem
        );
    }

    private buildCurrencies(currencyData: CurrencyInterface[], client: number): Currency[] {
        const currencies: Currency[] = [];

        _.forEach(currencyData, (element: CurrencyInterface) => {
            currencies.push(this.buildCurrency(element, client));
        });
        return currencies;
    }

    private prepareSettingCurrency() {
        this.settingsEmitter.setSettings({
            currencies: this.buildCurrencies(this.settings.dropdowns.currencies, this.settings.client),
            activeCurrency: this.buildCurrency(this.settings.dropdowns.systemCurrency, this.settings.client)
        });
        this.setSystemCurrency(this.settings.dropdowns.systemCurrency);
        return new Observable(
            observer => {
                observer.next('prepareSettingCurrency');
            });
    }

    public setSystemCurrency(currencyData: CurrencyInterface) {
        this.systemCurrency = this.buildCurrency(currencyData, this.settings.client);
    }
}
