import { ControlValueAccessor, AbstractControlDirective, AbstractControl } from '@angular/forms';
import { ErrorTranslationService } from '../../core/language/error.translation.service';
var TroiInputComponent = /** @class */ (function () {
    function TroiInputComponent(errorTranslationService) {
        this.errorTranslationService = errorTranslationService;
        this.type = 'text';
        this.hideError = false;
        this.innerValue = '';
        this.changed = [];
        this.touched = [];
    }
    TroiInputComponent.prototype.isValid = function () {
        return this.control && (this.control.valid || this.control.pristine);
    };
    Object.defineProperty(TroiInputComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (value) {
            if (this.innerValue !== value) {
                this.innerValue = value;
                this.changed.forEach(function (f) { return f(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    TroiInputComponent.prototype.showError = function () {
        return !this.hideError;
    };
    TroiInputComponent.prototype.getErrorMessage = function () {
        return this.errorTranslationService.getErrorMessages(this.control.errors);
    };
    TroiInputComponent.prototype.writeValue = function (value) {
        this.innerValue = value;
    };
    TroiInputComponent.prototype.registerOnChange = function (fn) {
        this.changed.push(fn);
    };
    TroiInputComponent.prototype.registerOnTouched = function (fn) {
        this.touched.push(fn);
    };
    TroiInputComponent.prototype.touch = function () {
        this.touched.forEach(function (f) { return f(); });
    };
    return TroiInputComponent;
}());
export { TroiInputComponent };
