import {Injectable} from '@angular/core';
import {DomService} from './dom.service';

@Injectable()
export class ModalService {
    public object;
    public events = [];
    constructor(private domService: DomService) {
    }

    private modalElementId = 'modal-container';
    private overlayElementId = 'overlay';

    init(component: any, inputs: object = {}, outputs: object = {}, maxHeight = '500px') {
        const componentConfig = {
            inputs: inputs,
            outputs: outputs
        };
        this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
        document.getElementById(this.modalElementId).className = 'show';
        document.getElementById(this.overlayElementId).className = 'show centered';
        const modalContent = document.getElementsByTagName('troi-modal-content')[0] as HTMLElement;
        if (modalContent) {
            modalContent.style.maxHeight = maxHeight;
        }
    }
    public addEvent(event) {
        this.events.push(event);
    }

    destroy() {
        this.domService.removeComponent();
        document.getElementById(this.modalElementId).className = 'hidden';
        document.getElementById(this.overlayElementId).className = 'hidden';
    }
}
