import {ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {ColumnType} from '../enum/column-type';
import {StringComponent} from '../string/string.component';

@Injectable()
export class ComponentFactoryService {

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {}

    public generateCommonComponent(type: ColumnType, data): ComponentRef<any> {
        let component = null;

        switch (type) {
            case ColumnType.CURRENCY:
                break;
            case ColumnType.DATE:
                break;
            case ColumnType.NUMBER:
                break;
            case ColumnType.STRING:
                component = StringComponent;
                break;
            case ColumnType.PROGRESS:
                break;
        }

        const factory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = factory.create(this.injector);
        // componentRef.instance.content = data;

        return componentRef;
    }

    public generateItemEditComponent(): ComponentRef<any> {
        const factory = this.componentFactoryResolver.resolveComponentFactory(StringComponent);
        const componentRef = factory.create(this.injector);
        componentRef.instance.content = 'jestem componentem z przyciskiem';

        return componentRef;
    }

}
