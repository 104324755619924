/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-modal-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./troi-modal-row.component";
var styles_TroiModalRowComponent = [i0.styles];
var RenderType_TroiModalRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiModalRowComponent, data: {} });
export { RenderType_TroiModalRowComponent as RenderType_TroiModalRowComponent };
export function View_TroiModalRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-row"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "modal-row--disabled": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "modal-row__label"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "modal-row__value"]], null, null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal-row"; var currVal_1 = _ck(_v, 2, 0, _co.disabled); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TroiModalRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-modal-row", [], null, null, null, View_TroiModalRowComponent_0, RenderType_TroiModalRowComponent)), i1.ɵdid(1, 49152, null, 0, i3.TroiModalRowComponent, [], null, null)], null, null); }
var TroiModalRowComponentNgFactory = i1.ɵccf("troi-modal-row", i3.TroiModalRowComponent, View_TroiModalRowComponent_Host_0, { disabled: "disabled" }, {}, ["[row-label]", "[row-value]"]);
export { TroiModalRowComponentNgFactory as TroiModalRowComponentNgFactory };
