import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FooterNetworkService} from '../network/footer.network.service';
import {TimetrackerInterface} from '../interfaces/timetracker.interface';
import {LegacyAjaxServerNetworkService} from '../../../core/network/legacyAjaxServer.network.service';
import {FooterSettingsService} from '../../../core/services/footer-settings.service';

@Injectable()
export class FooterService {
    public constructor(
        private footerNetworkService: FooterNetworkService,
        private legacyAjaxServer: LegacyAjaxServerNetworkService,
        public settingsService: FooterSettingsService
    ) {
    }

    public timetracker: TimetrackerInterface;

    public getTimetrackerSetting(): Observable<TimetrackerInterface> {
        return this.footerNetworkService.getTimetrackerSetting().map(result => {
            this.timetracker = result;
            return result;
        });
    }

    public checkIn(): void {
        this.legacyAjaxServer.postLegacyAjaxServer('WorkingTimeLogJQueryAjaxController', 'checkIn').subscribe(result => {
            this.refreshTimetrackerIfSuccess(result, 'checkInSuccess();');
        });
    }

    public checkOut(): void {
        this.legacyAjaxServer.postLegacyAjaxServer('WorkingTimeLogJQueryAjaxController', 'checkOut').subscribe(result => {
            this.refreshTimetrackerIfSuccess(result, 'checkOutSuccess();');
        });
    }

    public startBreak(): void {
        this.legacyAjaxServer.postLegacyAjaxServer('WorkingTimeLogJQueryAjaxController', 'startWorkingTimeLogBreak').subscribe(result => {
            this.refreshTimetrackerIfSuccess(result, 'startWorkingTimeLogBreakSuccess();');
        });
    }

    public stopBreak(): void {
        this.legacyAjaxServer.postLegacyAjaxServer('WorkingTimeLogJQueryAjaxController', 'stopWorkingTimeLogBreak').subscribe(result => {
            this.refreshTimetrackerIfSuccess(result, 'stopWorkingTimeLogBreakSuccess();');
        });
    }

    private refreshTimetrackerIfSuccess(result, success): void {
        if (result.indexOf(success) > -1) {
            this.getTimetrackerSetting().subscribe();
        }
    }
}
