/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accounts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header-notification/headerNotification.component.ngfactory";
import * as i3 from "../header-notification/headerNotification.component";
import * as i4 from "../../core/storage/storage.listener";
import * as i5 from "@angular/router";
import * as i6 from "../../shared/troi-footer/footer.component.ngfactory";
import * as i7 from "../../shared/troi-footer/footer.component";
import * as i8 from "../../core/emitters/settings.emitter";
import * as i9 from "../../core/colorSwitcher/colorSwitcher.service";
import * as i10 from "../../core/services/languages.service";
import * as i11 from "../../shared/troi-footer/services/footer.service";
import * as i12 from "../../shared/troi-modals/modal.service";
import * as i13 from "./accounts.component";
import * as i14 from "./common/services/accounting-settings.service";
import * as i15 from "../../core/services/basic-filters.service";
var styles_AccountsComponent = [i0.styles];
var RenderType_AccountsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountsComponent, data: {} });
export { RenderType_AccountsComponent as RenderType_AccountsComponent };
export function View_AccountsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "header-notification", [], null, null, null, i2.View_HeaderNotificationComponent_0, i2.RenderType_HeaderNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderNotificationComponent, [i4.StorageListener], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "hidden"], ["id", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "hidden"], ["id", "modal-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "accounts__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "troi-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(8, 573440, null, 0, i7.FooterComponent, [i8.SettingsEmitter, i4.StorageListener, i5.ActivatedRoute, i5.Router, i9.ColorSwitcherService, i10.LanguagesService, i11.FooterService, i12.ModalService], { settings: [0, "settings"], disabledCurrency: [1, "disabledCurrency"], loggingWorkingTimeEnabled: [2, "loggingWorkingTimeEnabled"], settingsEnabled: [3, "settingsEnabled"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = _co.settingsService.settings; var currVal_1 = true; var currVal_2 = ((_co.settingsService.settings == null) ? null : _co.settingsService.settings.settings.isLoggingWorkingTimeEnabled); var currVal_3 = true; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AccountsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "accounts", [], null, null, null, View_AccountsComponent_0, RenderType_AccountsComponent)), i1.ɵdid(1, 49152, null, 0, i13.AccountsComponent, [i14.AccountingSettingsService, i15.BasicFiltersService], null, null)], null, null); }
var AccountsComponentNgFactory = i1.ɵccf("accounts", i13.AccountsComponent, View_AccountsComponent_Host_0, {}, {}, []);
export { AccountsComponentNgFactory as AccountsComponentNgFactory };
