import {
    Component, HostListener,
    Input,
    OnInit
} from '@angular/core';

@Component({
    selector: 'troi-icon',
    templateUrl: './troi-icon.component.html',
    styleUrls: ['./troi-icon.component.scss']
})

export class TroiIconComponent implements OnInit {
  @Input() public icon: string;
  @Input() public large: boolean;
  @Input() public medium: boolean;
  @Input() public small: boolean;
  @Input() public bolder: boolean;
  @Input() public disabled: boolean;
  @Input() public size: string;
  constructor() { }

  ngOnInit() {
  }
}
