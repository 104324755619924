import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {UserService} from '../core/user/user.service';
import {BackendResponseInterface} from '../core/interfaces/backend.response.interface';
import {TranslateService} from '@ngx-translate/core';
import {IUser} from '../core/user/IUser';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    public constructor(
        private http: HttpClient,
        private userService: UserService,
        private translateService: TranslateService
    ) {}

    public login(username: string, password: string) {
        return this.http.post<any>('/site/login.php', {username: username, password: password})
            .map((userData: BackendResponseInterface<IUser> ) => {
                if (userData && userData.data.id) {
                    userData.data.isLogged = true;
                    this.userService.saveUser(userData.data);
                    this.translateService.setDefaultLang(userData.data.lang);
                }
            });
    }

    public getBannerData() {
        return this.http.get(
            environment.loginImageUrl,
            { responseType: 'text' });
    }

    public backendLogout() {
        this.http.post<BackendResponseInterface<any>>('/logout', {});
    }

    public logout(force: boolean = false) {
        if (!this.userService.getRememberMe() || force) {
            this.userService.removeUser();
        }
        this.userService.setLoggedIn(false);
    }
}
