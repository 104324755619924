import { ElementRef, OnChanges, SimpleChanges, ChangeDetectorRef, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Currency } from './currency';
import { MoneyFormat } from './moneyFormat';
import { Money } from './money';
export var CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return MoneyDirective; }),
    multi: true
};
var MoneyDirective = /** @class */ (function () {
    function MoneyDirective(el, cdRef) {
        this.el = el;
        this.cdRef = cdRef;
        this.innerValue = '';
        this.onTouchedCallback = function () { };
        this.onChangeCallback = function () { };
    }
    MoneyDirective.prototype.ngOnInit = function () {
        this.el.nativeElement.value = this.money.editableMoney;
    };
    Object.defineProperty(MoneyDirective.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChangeCallback(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    MoneyDirective.prototype.focus = function () {
        this.el.nativeElement.value = this.money.editableMoney;
        this.cdRef.detectChanges();
    };
    MoneyDirective.prototype.ngOnChanges = function (changes) {
        this.money.init();
        this.el.nativeElement.value = this.money.editableMoney;
        this.cdRef.detectChanges();
    };
    MoneyDirective.prototype.writeValue = function (value) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    };
    MoneyDirective.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    MoneyDirective.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    return MoneyDirective;
}());
export { MoneyDirective };
