export class UserModel {
    constructor(private _id: number,
                private _name: string,
                private _username: string,
                private _avatarUrl: string | null,
                private _isLogged: boolean,
                private _lang: string) {
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get username(): string {
        return this._username;
    }

    get avatarUrl(): string {
        return this._avatarUrl;
    }

    get isLogged(): boolean {
        return this._isLogged;
    }

    get lang() {
        return this._lang;
    }

    set name(value: string) {
        this._name = value;
    }

    set username(value: string) {
        this._username = value;
    }

    set avatarUrl(value: string) {
        this._avatarUrl = value;
    }

    set isLogged(value: boolean) {
        this._isLogged = value;
    }

    set lang(value: string) {
        this._lang = value;
    }
}
