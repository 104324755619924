import { ElementRef, forwardRef, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { TroiDropdownListComponent } from '../troi-dropdown-list/troi-dropdown-list.component';
var noop = function () {
};
var ɵ0 = noop;
export var TROI_DROPDOWN_SELECT_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return TroiDropdownSelectComponent; }),
    multi: true
};
var TroiDropdownSelectComponent = /** @class */ (function () {
    function TroiDropdownSelectComponent(element) {
        this.static = false;
        this.noBorder = false;
        this.fullWidth = false;
        this.forceOpen = false;
        this.top = false;
        this.enabled = true;
        this.clearButton = false;
        this.noMinWith = false;
        this.width = '100%';
        this.toRight = false;
        this.placeholder = '';
        this.search = false;
        this.disable = false;
        this.fieldInvalid = false;
        this.validationEnabled = false;
        this.selected = new EventEmitter();
        this.innerValue = '';
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.element = element;
    }
    Object.defineProperty(TroiDropdownSelectComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChangeCallback(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    TroiDropdownSelectComponent.prototype.ngOnInit = function () {
    };
    TroiDropdownSelectComponent.prototype.onOpen = function (state) {
        this.open = state;
    };
    TroiDropdownSelectComponent.prototype.onSelect = function (event) {
        this.element.nativeElement.focus();
        this.value = event.value;
        this.selected.emit(event.value);
        this.forceOpen = false;
    };
    Object.defineProperty(TroiDropdownSelectComponent.prototype, "label", {
        get: function () {
            var _this = this;
            if (this.options && this.options.filter(function (option) { return (option.value === _this.innerValue); })[0]) {
                return this.options.filter(function (option) { return (option.value === _this.innerValue); })[0].label;
            }
        },
        enumerable: true,
        configurable: true
    });
    TroiDropdownSelectComponent.prototype.onBlur = function () {
        this.onTouchedCallback();
    };
    TroiDropdownSelectComponent.prototype.writeValue = function (value) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    };
    TroiDropdownSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    TroiDropdownSelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    TroiDropdownSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes.initValue && !_.isUndefined(changes.initValue.currentValue)) {
            this.value = changes.initValue.currentValue;
        }
        if (changes.forceOpen) {
            this.open = this.forceOpen;
        }
    };
    TroiDropdownSelectComponent.prototype.clearValue = function (event) {
        event.stopPropagation();
        this.value = null;
        this.optionList.hideList();
        this.selected.emit(null);
    };
    TroiDropdownSelectComponent.prototype.onKeydownHandler = function (event) {
        this.forceOpen = !this.forceOpen;
        event.preventDefault();
        event.stopPropagation();
    };
    return TroiDropdownSelectComponent;
}());
export { TroiDropdownSelectComponent };
export { ɵ0 };
