import {HostListener} from '@angular/core';
import {ModalService} from '../troi-modals/modal.service';

export class BaseModalComponent {
    public constructor(public modalService: ModalService) {
    }

    @HostListener('document:keydown.escape', ['$event'])
    private keyPressed(event: KeyboardEvent) {
        this.close();
    }

    public close() {
        this.modalService.destroy();
    }
}
