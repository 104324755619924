import {MoneyFormat} from './moneyFormat';

export interface CurrencyStorage {
    client: string;
    currency: string;
}

export class Currency {
    constructor(
        public client: number,
        public id: number,
        public symbol: string,
        public name: string,
        public format: MoneyFormat,
        public exchangeRate: number,
        public isSystem: boolean) {
    }
}
