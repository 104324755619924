import {
    Component,
    Input, OnChanges, OnInit
} from '@angular/core';

@Component({
    selector: 'troi-modal-section',
    templateUrl: './troi-modal-section.component.html',
    styleUrls: ['./troi-modal-section.component.scss']
})

export class TroiModalSectionComponent implements  OnInit, OnChanges {
  constructor() { }
  @Input() public label: string;
  @Input() public forceOpen = false;
  public open = false;

  ngOnInit() {
    this.open = this.forceOpen;
  }

  public toggleSection(): void {
    this.open = !this.open;
  }

    ngOnChanges(changes) {
        if (changes.forceOpen) {
            this.open = changes.forceOpen.currentValue;
        }
    }
}
