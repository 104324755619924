import {Component, Input} from '@angular/core';

@Component({
    selector: 'troi-loading',
    templateUrl: './troi-loading.component.html',
    styleUrls: ['./troi-loading.component.scss']
})
export class TroiLoadingComponent {
    @Input() public rows = 1;
    @Input() public columns: Array<string> = [];
}
