import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TabInterface} from './tab.interface';
import {ParentWindowRef} from '../../core/services/parent-window-ref.service';

@Component({
  selector: 'troi-data-listing-tabs',
  templateUrl: './troi-data-listing-tabs.component.html',
  styleUrls: ['./troi-data-listing-tabs.component.scss']
})
export class TroiDataListingTabsComponent implements OnInit {
  public isEditMenuOpen = false;
  @Input() tabs: TabInterface[] = [];
  @Input() newButtonDisabled = false;
  @Input() moreActionButtonDisabled = false;
  @Input() moreActionsOptions: [];

  @Output() newClicked = new EventEmitter();
  @Output() moreActionsClicked = new EventEmitter();
  @Output() tabChanged = new EventEmitter();

  constructor(private parentWindowRef: ParentWindowRef) { }

  ngOnInit() {}

  private changeParentWindowUrl(url: string) {
    if (url) {
      const tempUrl = `index.php?page=${url}`;
      this.parentWindowRef.nativeHistory.pushState('', '', tempUrl);
    }
  }

  private changeTab(tab: TabInterface) {
    this.tabChanged.emit(tab);
    this.changeParentWindowUrl(tab.parentWindowUrl);
  }

  public moreActionOptionClick(event) {
    this.moreActionsClicked.emit(event);
  }

  public onOpeningState(event) {
    this.isEditMenuOpen = event;
  }

  public emitOnNew() {
    this.newClicked.emit(true);
  }
}
