import {Injectable} from '@angular/core';
import {AccessRight} from '../../../../core/interfaces/backend.response.interface';
import {Module} from '../enums/module';

@Injectable()
export class AccountingAccessRightService {
    public accessRights = {
        [Module.ACCOUNT_LIST]: undefined,
        [Module.ACCOUNT_GROUP]: undefined
    };

    constructor() {}

    setAccessRight(module: Module, accessRight: AccessRight) {
        this.accessRights[module] = accessRight;
    }

    accessIsSet(module: Module) {
        return this.accessRights[module] !== undefined;
    }

    hasReadAccess(module: Module) {
        return this.accessRights[module] === AccessRight.READ ||
            this.accessRights[module] === AccessRight.WRITE;
    }

    hasWriteAccess(module: Module) {
        return this.accessRights[module] !== undefined &&
            this.accessRights[module] === AccessRight.WRITE;
    }
}
