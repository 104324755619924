import {Injectable} from '@angular/core';
import {BasicFiltersNetwork} from '../network/basic-filters.network';
import {ClientInterface} from '../interfaces/client.interface';
import {TroiDropdownListModel} from '../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import * as _ from 'lodash';
import {Subject} from 'rxjs';

@Injectable()
export class BasicFiltersService {
    clients: ClientInterface[] = [];
    clientsLoaded = new Subject();

    constructor(private network: BasicFiltersNetwork) {}

    fetchClients() {
        this.network.getClients(true).subscribe((result) => {
            this.clients = result.data;
            this.clientsLoaded.next(true);
        });
    }

    generateClientsDropdown(): Array<TroiDropdownListModel> {
        const list = [];
        _.forEach(this.clients, (element: ClientInterface) => {
            list.push({
                label: element.name,
                value: element.id,
                active: true
            });
        });

        return list;
    }

    defaultClientId(): number {
        const client = _.find(this.clients, ['default', true]);

        return client ? client.id : _.first(this.clients).id;
    }
}
