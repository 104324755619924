import {Component, Input, forwardRef, OnInit} from '@angular/core';
import {AbstractControl, AbstractControlDirective, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as _ from 'lodash';

@Component({
    selector: 'troi-switch',
    templateUrl: './troi-switch.component.html',
    styleUrls: ['./troi-switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TroiSwitchComponent),
            multi: true
        }
    ]
})
export class TroiSwitchComponent implements ControlValueAccessor, OnInit {
    @Input() label = '';
    @Input() name: string;
    @Input('small') small: boolean;
    @Input() scrollTo = false;
    @Input() public control: AbstractControlDirective | AbstractControl;

    public _value = false;
    private onTouchedCallback: () => {};
    private onChangeCallback: (_: any) => {};

    ngOnInit(): void {
        this.small = !_.isUndefined(this.small);
    }

    get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
        this.onChangeCallback(val);
        this.onTouchedCallback();
    }

    writeValue(obj: any) {
        this._value = obj;
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    switch() {
        this.value = !this._value;
    }

    public scrollToView(element) {
        if (this.scrollTo) {
            element.target.scrollIntoView();
        }
    }
}
