import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {FooterSettingsInterface} from '../interfaces/footer-settings.interface';


@Injectable()
export class SettingsEmitter {
    private subject = new Subject<FooterSettingsInterface>();
    setSettings(settings: FooterSettingsInterface) {
        this.subject.next(settings);
    }

    getEmitter(): Observable<any> {
        return this.subject.asObservable();
    }
}
