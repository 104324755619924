<div class="modal-header">
  <div class="modal-header__name">
    <ng-content>
    </ng-content>
  </div>
  <div class="modal-header__close"
       *ngIf="!noCloseButton"
       (click)="close()">
    <troi-icon icon="icon-close"></troi-icon>
  </div>
</div>
