import { Injectable } from '@angular/core';
import { TroiInlineTabInterface } from '../interfaces/troi-inline-tab.interface';
import * as _ from 'lodash';
import { EventStrategies } from '../eventStrategies/event-strategies.enum';
import { VirtualEventStrategiesFactory } from '../eventStrategies/virtual-event-strategies.factory';
import { TroiDropDownCloseService } from '../../troi-dropdown-list/services/troi-dropdown-close.service';

@Injectable()
export class TroiInlineTabService {
    public tabs: Array<TroiInlineTabInterface> = [];
    public selectedElement: TroiInlineTabInterface = null;
    private maxOrder = null;
    private minOrder = null;

    constructor(
        private strategies: VirtualEventStrategiesFactory,
        private closeSelects: TroiDropDownCloseService
    ) {
    }

    addTabbableElement(element: any, id: string, order: number): TroiInlineTabInterface {
        const el: TroiInlineTabInterface = {
            element: element,
            id: id,
            order: order,
            eventStrategies: {
                openStrategy: EventStrategies.DBLCLICK,
                closeStrategy: EventStrategies.DBLCLICK
            }
        };
        this.tabs.push(el);

        return el;
    }

    edit() {
        this.closeSelects.close(new Event('click'));
        this.focus(this.selectedElement);
    }

    nextByOrder() {
        this.closeSelects.close(new Event('click'));
        if (!this.selectedElement) {
            return;
        }
        this.focus(this.selectedElement, false);
        this.selectedElement = this.findNextElement();
        if (!this.selectedElement) {
            this.selectedElement = this.findElementByOrder(this.minOrder);
        }
        this.edit();
    }

    public selectElement(id: string) {
        this.selectedElement = _.clone(this.findElement(id));
    }

    public findNextElement(): TroiInlineTabInterface {
        return this.tabs.sort((a, b) => a.order - b.order)
            .find(item => item.order > this.selectedElement.order);
    }

    private findElement(id: string): TroiInlineTabInterface {
        return _.find(this.tabs, (data) => {
            return data.id === id;
        });
    }
    private findElementByOrder(order: number): TroiInlineTabInterface {
        return _.find(this.tabs, (data) => {
            return data.order === order;
        });
    }

    public focus(element: TroiInlineTabInterface, open = true) {
        _.forEach(this.strategies.getStrategies(), (strategy) => {
            const evetnStrategy = open ? element.eventStrategies.openStrategy : element.eventStrategies.closeStrategy;
            if (strategy.support(evetnStrategy)) {
                strategy.handle(element.element);
            }
        });
    }

    public lastElement(): TroiInlineTabInterface {
        return _.last(this.tabs);
    }

    challengeMaxOrder(order: number) {
        if (this.maxOrder && this.maxOrder >= order) { return; }

        this.maxOrder = order;
    }

    challengeMinOrder(order: number) {
        if (this.minOrder && this.minOrder <= order) { return; }

        this.minOrder = order;
    }
}
