import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './core/storage/storage.service';
import {IUser} from './core/user/IUser';
import {environment} from '../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {StorageKeys} from './core/storage/storage.keys';
import {StorageListener} from './core/storage/storage.listener';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public themeColor: string;

    constructor(private translate: TranslateService,
                private storageService: StorageService,
                private route: ActivatedRoute,
                private storageListener: StorageListener) {
    }

    public ngOnInit(): void {
        this.route.queryParams.subscribe(queryParams => {
                const user: IUser = this.storageService.getItem(StorageKeys.USER);

                this.translate.setDefaultLang(this.getLanguage(queryParams, user));
            }
        );
        this.themeColor = this.getThemeColorFromStorage();
        this.storageListener.storageChanges.subscribe((changesData: any) => {
            switch (changesData.key) {
                case StorageKeys.THEME_COLOR:
                    this.themeColor = changesData.value;
                    break;
                case StorageKeys.LANG:
                    this.switchLanguage(changesData.value);
                    break;
            }
        });
    }

    public switchLanguage(language: string) {
        this.translate.use(language);
    }

    private getLanguage(queryParams, user: IUser) {
        let language = environment.defaultLanguage;
        const storageLang = this.storageService.getItem(StorageKeys.LANG);

        if (user && !!user.lang.length) {
            language = user.lang;
        }

        if (storageLang) {
            language = storageLang;
        }

        return queryParams.lang ? queryParams.lang : language;
    }

    private getThemeColorFromStorage(): string {
        const color = this.storageService.getItem(StorageKeys.THEME_COLOR);

        return color ? color : '';
    }
}
