import {
  Component,
  Input
} from '@angular/core';
import {FileStatusEnum} from './file-status.enum';

@Component({
    selector: 'troi-upload-icon',
    templateUrl: './troi-upload-icon.component.html',
    styleUrls: ['./troi-upload-icon.component.scss']
})

export class TroiUploadIconComponent {
  FileStatusEnum = FileStatusEnum;
  @Input() public status: string;
  @Input() public number: number;
  @Input() public progress = 100;
  constructor() { }

  actualProgress(): string {
      const progress = this.progress > 100 || this.progress < 0 ? 100 : this.progress;

      return 'progress-' + progress;
  }
}
