import {MultipleMessageInterface} from '../interfaces/multipleMessage.interface';

export enum TroiNotificationType {
    ERROR = 1,
    WARNING,
    INFO,
    SUCCESS
}

export class TroiNotification {
    public type: number;
    public message: string;
    public multipleMessage: MultipleMessageInterface[];

    constructor(
        type: number,
        message: string,
        multipleMessage: MultipleMessageInterface[] = []
    ) {
        this.type = type;
        this.message = message;
        this.multipleMessage = multipleMessage;
    }
}
