<header-notification></header-notification>
<router-outlet></router-outlet>
<div id="overlay" class="hidden">
    <div id="modal-container" class="hidden"></div>
</div>
<div class="accounts__footer">
    <troi-footer
            [settings]="settingsService.settings"
            [loggingWorkingTimeEnabled]="settingsService.settings?.settings.isLoggingWorkingTimeEnabled"
            [disabledCurrency]="true"
            [settingsEnabled]="true"
    ></troi-footer>
</div>
