
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {StorageNotificationService} from '../notifications/storageNotification.service';
import {BackendErrorTranslationService} from '../services/backendError.translation.service';

export enum ErrorStatus {
    Success = 200,
    Unauthorized = 401,
    NoAccessToAction = 403,
    ERROR = 500,
    USER_ERROR = 400
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private notificationService: StorageNotificationService,
        private errorService: BackendErrorTranslationService
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.overrideRequest(req, next);
    }

    private overrideRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === ErrorStatus.Unauthorized) {
                        this.router.navigate(['/login']);
                    }
                    if (err.status === ErrorStatus.NoAccessToAction) {
                        this.errorService.showAccessToActionDeniedError();
                    }
                    if (err.status === ErrorStatus.USER_ERROR ||
                        err.status === 404 ||
                        err.status === ErrorStatus.ERROR ||
                        err.status === 0) {
                        try {
                            this.errorService.showError(err.error.data);
                        } catch (e) {
                            this.errorService.getGeneralError().subscribe((error) => {
                                this.notificationService.showError(error);
                            });
                        }
                    }

                    return observableThrowError(err);
                })
            );
    }
}

export let HttpErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
};
