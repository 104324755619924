/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-dropdown-option.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../troi-loading/troi-loading.component.ngfactory";
import * as i3 from "../troi-loading/troi-loading.component";
import * as i4 from "@angular/common";
import * as i5 from "./troi-dropdown-option.component";
var styles_TroiDropdownOptionComponent = [i0.styles];
var RenderType_TroiDropdownOptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiDropdownOptionComponent, data: {} });
export { RenderType_TroiDropdownOptionComponent as RenderType_TroiDropdownOptionComponent };
function View_TroiDropdownOptionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "troi-dropdown-option__count"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.count; _ck(_v, 1, 0, currVal_0); }); }
function View_TroiDropdownOptionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-loading", [["class", "troi-dropdown-option__loading"]], null, null, null, i2.View_TroiLoadingComponent_0, i2.RenderType_TroiLoadingComponent)), i1.ɵdid(1, 49152, null, 0, i3.TroiLoadingComponent, [], null, null)], null, null); }
function View_TroiDropdownOptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "troi-dropdown-option"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "troi-dropdown-option--selected": 0, "troi-dropdown-option--disabled": 1, "troi-dropdown-option--group": 2, "troi-dropdown-option--hasOverride": 3 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "troi-dropdown-option__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiDropdownOptionComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiDropdownOptionComponent_3)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "troi-dropdown-option"; var currVal_1 = _ck(_v, 2, 0, (_co.selected && !_co.group), _co.disabled, _co.group, _co.hasOverride); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = (_co.count !== undefined); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.loading; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 4, 0, currVal_2); }); }
export function View_TroiDropdownOptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiDropdownOptionComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TroiDropdownOptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-dropdown-option", [], null, null, null, View_TroiDropdownOptionComponent_0, RenderType_TroiDropdownOptionComponent)), i1.ɵdid(1, 49152, null, 0, i5.TroiDropdownOptionComponent, [], null, null)], null, null); }
var TroiDropdownOptionComponentNgFactory = i1.ɵccf("troi-dropdown-option", i5.TroiDropdownOptionComponent, View_TroiDropdownOptionComponent_Host_0, { disabled: "disabled", selected: "selected", active: "active", value: "value", label: "label", count: "count", loading: "loading", group: "group", hasOverride: "hasOverride" }, {}, []);
export { TroiDropdownOptionComponentNgFactory as TroiDropdownOptionComponentNgFactory };
