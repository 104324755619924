<div class="troi-loading" *repeat="rows">
    <div class="troi-loading__placeholder
                troi-loading__placeholder--item
                troi-loading__placeholder--animated-background">
        <div class="troi-loading__row"
             [ngClass]="{ 'troi-loading__row--single': !columns.length }">
            <div class="troi-loading__column"
                 *ngFor="let column of columns; let even = even"
                 [ngClass]="{ 'troi-loading__column--even': even }"
                 [style.width]="column"></div>
        </div>
    </div>
</div>
