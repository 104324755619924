import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import {FooterService} from './services/footer.service';
import {FormControl, FormGroup} from '@angular/forms';
import {StorageListener} from '../../core/storage/storage.listener';
import {ColorSwitcherService} from '../../core/colorSwitcher/colorSwitcher.service';
import {LanguagesService} from '../../core/services/languages.service';
import {StorageKeys} from '../../core/storage/storage.keys';
import {Currency} from '../troi-money/currency';
import {Colors} from '../../core/colorSwitcher/colors.enum';
import {SettingsEmitter} from '../../core/emitters/settings.emitter';
import {FooterSettingsInterface} from '../../core/interfaces/footer-settings.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalService} from '../troi-modals/modal.service';
import {SettingsModalComponent} from './modal/settings-modal/settings-modal.component';

interface Color {
    color: string;
    selected: boolean;
}

@Component({
    selector: 'troi-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnChanges {
    public footerForm: FormGroup;
    public colors: Color[];
    public languageList: object[] = [];
    public dataLanguageList: object[] = [];
    public currencyList: object[] = [];

    @Input() settings: FooterSettingsInterface;
    @Input() disabledCurrency = false;
    @Input() loggingWorkingTimeEnabled = false;
    @Input() settingsEnabled = false;
    @Output() currencyChanged  = new EventEmitter<Currency>();

    constructor(
        private settingsEmitter: SettingsEmitter,
        private storageListener: StorageListener,
        private route: ActivatedRoute,
        private router: Router,
        public colorSwitcherService: ColorSwitcherService,
        public languageService: LanguagesService,
        public footerService: FooterService,
        public modalService: ModalService
    ) {
        this.footerService.getTimetrackerSetting().subscribe();
        this.buildForm();
        this.settingsEmitter.getEmitter().subscribe((settings: FooterSettingsInterface) => {
            this.settings = settings;
            this.populateSettings(settings);
        });
        this.storageListener.storageChanges.subscribe((changesData: any) => {
            switch (changesData.key) {
                case StorageKeys.LANG:
                    this.footerForm.controls['language'].patchValue(
                        changesData.value
                    );
                    break;
                case StorageKeys.DATA_LANG:
                    this.footerForm.controls['dataLanguage'].patchValue(
                        changesData.value
                    );
                    break;
                case StorageKeys.ACTIVE_CURRENCY:
                    this.changeCurrency(changesData.value.currency);
                    this.populateSettings(this.settings);
                    break;
                case StorageKeys.THEME_COLOR:
                    this.colorSwitcherService.switchColor(changesData.value);
                    break;
            }
        });
        this.route.url.subscribe(() => {
            this.footerService.settingsService.changeRouteKey(this.router.url);
        });
    }

    populateSettings(settings: FooterSettingsInterface) {
        this.currencyList = this.buildCurrencyList(settings.currencies);
        this.footerForm.controls['currency'].patchValue(
            settings.activeCurrency.id
        );
    }

    buildForm() {
        this.footerForm = new FormGroup({
            currency: new FormControl(null),
            language: new FormControl(this.languageService.getLanguage()),
            dataLanguage: new FormControl(this.languageService.getDataLanguage()),
        });
        this.colors = this.colorList();
        this.languageList = this.buildLanguageList(this.languageService.getSystemLanguages(), 'Navigation: ');
        this.dataLanguageList = this.buildLanguageList(this.languageService.getSystemLanguages(), 'Data: ');
    }

    changeCurrency(currencyId: string) {
        const currency = _.find(this.settings.currencies, (currencyObject: Currency) => {
            return currencyObject.id === parseInt(currencyId);
        });
        if (currency) {
            this.currencyChanged.emit(currency);
        }
    }

    changeColor() {
        const currentColor = this.colorSwitcherService.color;
        const nextColorIndex = (_.findIndex(this.colors, {color: currentColor}) + 1) % 5;
        this.colorSwitcherService.saveColor(this.colors[nextColorIndex].color);
    }

    buildLanguageList(elements: Array<string>, labelPrefix: string = '') {
        return elements.map(lang => {
            return {
                active: true,
                value: lang,
                label: labelPrefix + lang
            };
        });
    }

    // todo: change object to some interface
    buildCurrencyList(currencies: Currency[]): Array<object> {
        const currencyList = [];
        _.forEach(currencies, (currency) => {
            currencyList.push({
                active: true,
                value: currency.id,
                label: currency.symbol
            });
        });

        return currencyList;
    }

    public addWorkingTime(): void {
        window.top['editWorkingTimeLog']();
    }

    public logout(): void {
        const url = window.top.location.href;
        window.top.location.replace('/logout?ref=' + encodeURIComponent(url));
    }

    public openSettings() {
        this.modalService.object = this.footerService.settingsService.readFromLS();
        this.modalService.init(SettingsModalComponent);
    }

    private colorList(): Color[] {
        return Object.entries(Colors).map(el => {
            return {
                color: el[1],
                selected: this.colorSwitcherService.color === el[1]
            };
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings && changes.settings.currentValue && changes.settings.currentValue.dropdowns) {
            this.populateSettings({
                currencies: changes.settings.currentValue.dropdowns.currencies,
                activeCurrency: changes.settings.currentValue.dropdowns.systemCurrency
            });
        }
    }
}
