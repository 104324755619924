import { Directive, Input, OnInit, ElementRef, HostListener, OnChanges } from '@angular/core';
import { TroiInlineTabService } from './services/troi-inline-tab.service';
import { EventStrategies } from './eventStrategies/event-strategies.enum';
import { TroiInlineTabInterface } from './interfaces/troi-inline-tab.interface';

@Directive({
    selector: '[inline-tab]'
})
export class TroiInlineTabDirective implements OnInit, OnChanges {
    @Input() cssSelector: string;
    @Input() withSelection: boolean;
    private id: string;
    @Input() openStrategy: EventStrategies;
    @Input() closeStrategy: EventStrategies;
    @Input() order: number;
    private selectedElement: TroiInlineTabInterface = null;

    constructor(
        private troiInlineTabService: TroiInlineTabService,
        private element: ElementRef
    ) {
        this.id = this.generateId();
        this.selectedElement = this.troiInlineTabService.addTabbableElement(this.element, this.id, this.order);
    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        this.updateItem(
            changes.order ? changes.order.currentValue : null,
            changes.openStrategy ? changes.openStrategy.currentValue : null,
            changes.closeStrategy ? changes.closeStrategy.currentValue : null
            );
    }

    @HostListener('dblclick', ['$event'])
    setFocusedElement(event: MouseEvent) {
        if (!event.isTrusted) {
            return;
        }
        this.troiInlineTabService.selectElement(this.id);

        this.selectedElement = this.troiInlineTabService.selectedElement;
        event.stopPropagation();
    }

    private updateItem(order: number, openStrategy: EventStrategies, closeStrategy: EventStrategies) {
        if (closeStrategy) { this.selectedElement.eventStrategies.closeStrategy = closeStrategy; }
        if (openStrategy) { this.selectedElement.eventStrategies.openStrategy = openStrategy; }
        if (order) {
            this.selectedElement.order = order;
            this.troiInlineTabService.challengeMaxOrder(order);
            this.troiInlineTabService.challengeMinOrder(order);
        }
    }

    private generateId(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
