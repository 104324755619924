/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-dropdown-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../troi-dropdown-option/troi-dropdown-option.component.ngfactory";
import * as i5 from "../troi-dropdown-option/troi-dropdown-option.component";
import * as i6 from "@angular/common";
import * as i7 from "./troi-dropdown-list.component";
import * as i8 from "./services/troi-dropdown-close.service";
var styles_TroiDropdownListComponent = [i0.styles];
var RenderType_TroiDropdownListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiDropdownListComponent, data: {} });
export { RenderType_TroiDropdownListComponent as RenderType_TroiDropdownListComponent };
function View_TroiDropdownListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "input", [["class", "troi-dropdown-list__search-input"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "click"], [null, "keyup"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.searchClicked($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.filterOptions(_co.searchValue) !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.searchValue = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.searchValue; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 6).transform("Booking.labels.search")), "..."); var currVal_1 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 5).ngClassValid; var currVal_6 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_TroiDropdownListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "troi-dropdown-option", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_v.context.$implicit.disabled && _co.onClickItem(_v.context.$implicit.value, $event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TroiDropdownOptionComponent_0, i4.RenderType_TroiDropdownOptionComponent)), i1.ɵdid(2, 49152, null, 0, i5.TroiDropdownOptionComponent, [], { disabled: [0, "disabled"], selected: [1, "selected"], active: [2, "active"], value: [3, "value"], label: [4, "label"], count: [5, "count"], loading: [6, "loading"], group: [7, "group"], hasOverride: [8, "hasOverride"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.disabled; var currVal_1 = (_v.context.$implicit.value === _co.innerValue); var currVal_2 = _v.context.$implicit.active; var currVal_3 = _v.context.$implicit.value; var currVal_4 = _v.context.$implicit.label; var currVal_5 = _v.context.$implicit.count; var currVal_6 = _v.context.$implicit.loading; var currVal_7 = _v.context.$implicit.group; var currVal_8 = _v.context.$implicit.hasOverride; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_TroiDropdownListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "span", [["class", "troi-dropdown-list__list-wrapper"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "troi-dropdown-list__list-wrapper--top": 0, "troi-dropdown-list__list-wrapper--no-min-width": 1 }), i1.ɵdid(3, 278528, null, 0, i6.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "width": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiDropdownListComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "troi-dropdown-list__list"], ["tabindex", "0"]], null, [[null, "focusout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focusout" === en)) {
        var pd_0 = ((!_co.search && _co.hideList()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiDropdownListComponent_3)), i1.ɵdid(9, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "troi-dropdown-list__list-wrapper"; var currVal_1 = _ck(_v, 2, 0, _co.top, _co.noMinWith); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 4, 0, _co.width); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.search; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.filteredOptions; _ck(_v, 9, 0, currVal_4); }, null); }
function View_TroiDropdownListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "input", [["class", "troi-dropdown-list__search-input"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "click"], [null, "keyup"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.searchClicked($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.filterOptions(_co.searchValue) !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.searchValue = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.searchValue; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_TroiDropdownListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [["class", "troi-dropdown-list__list-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiDropdownListComponent_5)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "span", [["class", "troi-dropdown-list__list"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "top": 0, "noMinWith": 1 }), i1.ɵdid(6, 278528, null, 0, i6.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "width": 0 }), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.search; _ck(_v, 2, 0, currVal_0); var currVal_1 = "troi-dropdown-list__list"; var currVal_2 = _ck(_v, 5, 0, _co.top, _co.noMinWith); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 7, 0, _co.width); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_TroiDropdownListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [["class", "troi-dropdown-list"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "troi-dropdown-list--static": 0, "troi-dropdown-list--disabled": 1 }), (_l()(), i1.ɵeld(3, 0, [["troiDropDownListSelect", 1]], null, 5, "span", [["class", "troi-dropdown-list__select"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiDropdownListComponent_1)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiDropdownListComponent_4)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "troi-dropdown-list"; var currVal_1 = _ck(_v, 2, 0, _co.static, _co.disable); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.openState && _co.options) && _co.options.length); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.openState && ((_co.options && !_co.options.length) || !_co.options)); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_TroiDropdownListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-dropdown-list", [], null, [[null, "keydown.space"], [null, "keydown.arrowup"], [null, "keydown.arrowdown"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown.space" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.arrowup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onArrowUpHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.arrowdown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onArrowDownHandler($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onKeyDownHandler($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_TroiDropdownListComponent_0, RenderType_TroiDropdownListComponent)), i1.ɵdid(1, 573440, null, 0, i7.TroiDropdownListComponent, [i1.ElementRef, i8.TroiDropDownCloseService], null, null)], null, null); }
var TroiDropdownListComponentNgFactory = i1.ɵccf("troi-dropdown-list", i7.TroiDropdownListComponent, View_TroiDropdownListComponent_Host_0, { options: "options", static: "static", forceOpen: "forceOpen", width: "width", value: "value", top: "top", noMinWith: "noMinWith", disable: "disable", search: "search" }, { selectedEmitter: "selectedEmitter", openChange: "openChange" }, ["*", "[options]"]);
export { TroiDropdownListComponentNgFactory as TroiDropdownListComponentNgFactory };
