import { Injectable } from '@angular/core';
import { StorageListener } from '../storage/storage.listener';
import { StorageKeys } from '../storage/storage.keys';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class ColorSwitcherService {
    constructor(
        private storageListener: StorageListener,
        private storageService: StorageService
    ) {
    }

    get color() {
        const color = this.storageService.getItem(StorageKeys.THEME_COLOR);

        return color ? color : 'blue';
    }

    switchColor(color: string) {
        this.storageService.setItem(StorageKeys.THEME_COLOR, color);
    }

    saveColor(color: string) {
        this.storageListener.store(StorageKeys.THEME_COLOR, color);
    }
}
