import * as tslib_1 from "tslib";
import { StorageService } from '../storage/storage.service';
import { Subject } from 'rxjs';
import { UserSettings } from './user-settings';
var FooterSettingsService = /** @class */ (function (_super) {
    tslib_1.__extends(FooterSettingsService, _super);
    function FooterSettingsService(storage) {
        var _this = _super.call(this, storage) || this;
        _this.storage = storage;
        _this.settingsKey = 'footer-settings';
        _this.newSettingsDataSet = new Subject();
        _this.initLSUserData(_this.settingsKey);
        return _this;
    }
    FooterSettingsService.prototype.changeRouteKey = function (key) {
        this.actualRoute = key;
    };
    FooterSettingsService.prototype.readFromLS = function () {
        var perPage = this.getSettingForUser(this.settingsKey)[this.actualRoute];
        return perPage ? perPage : this.getDefaultSettings();
    };
    FooterSettingsService.prototype.setPerPage = function (perPage) {
        var newSettings = {
            perPage: perPage
        };
        var settings = this.getAllUsersSettings();
        settings[this.getUserId()][this.settingsKey][this.actualRoute] = newSettings;
        this.saveUserSetting(settings);
        this.newSettingsDataSet.next(newSettings);
    };
    FooterSettingsService.prototype.getDefaultSettings = function () {
        return {
            perPage: 50
        };
    };
    return FooterSettingsService;
}(UserSettings));
export { FooterSettingsService };
