/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./troi-icon.component";
var styles_TroiIconComponent = [i0.styles];
var RenderType_TroiIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiIconComponent, data: {} });
export { RenderType_TroiIconComponent as RenderType_TroiIconComponent };
export function View_TroiIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], [[2, "troi-icon--large", null], [2, "troi-icon--medium", null], [2, "troi-icon--small", null], [2, "troi-icon--bolder", null], [2, "troi-icon--disabled", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "font-size": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.icon; _ck(_v, 1, 0, currVal_5); var currVal_6 = _ck(_v, 3, 0, _co.size); _ck(_v, 2, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.large; var currVal_1 = _co.medium; var currVal_2 = _co.small; var currVal_3 = _co.bolder; var currVal_4 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_TroiIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-icon", [], null, null, null, View_TroiIconComponent_0, RenderType_TroiIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.TroiIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TroiIconComponentNgFactory = i1.ɵccf("troi-icon", i3.TroiIconComponent, View_TroiIconComponent_Host_0, { icon: "icon", large: "large", medium: "medium", small: "small", bolder: "bolder", disabled: "disabled", size: "size" }, {}, []);
export { TroiIconComponentNgFactory as TroiIconComponentNgFactory };
