import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'troi-paginator',
  templateUrl: './troi-paginator.component.html',
  styleUrls: ['./troi-paginator.component.scss']
})
export class TroiPaginatorComponent implements OnInit, OnChanges {

    pages = [];
    @Input() currentPage = 1;
    @Input() perPage = 1;
    @Input() elementsCount = 1;
    @Input() limit = 5;
    @Output() pageChanged = new EventEmitter();
  constructor() { }

  ngOnInit() {
      this.generatePages();
  }

    isLastPage(): boolean {
        return this.currentPage >= this.pagesCount();
    }

    isFirstPage(): boolean {
      return this.currentPage === 1;
    }

    pagesCount(): number {
        return Math.ceil(this.elementsCount / this.perPage);
    }


    generatePages() {
      this.pages = [];

      for (
           let x = Math.max(1, this.currentPage - Math.round(this.limit / 2) + 1);
           x <= this.pagesCount() && this.pages.length < this.limit;
           x++
      ) {
          this.pages.push(x);
      }

      if (this.pages.length < this.limit) {
          if (this.pagesCount() - this.currentPage <= this.currentPage - Math.round(this.limit / 2) + 1) {
              for (let x = this.pages[0] - 1; this.pages.length < this.limit && x > 0; x--) {
                  this.pages.unshift(x);
              }
          }
      }
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.currentPage) {
          this.generatePages();
      }
    }
}
