import {NgZone, Directive, Input, OnInit, ElementRef} from '@angular/core';

@Directive({
    selector: '[focus-directive]'
})
export class FocusDirective implements OnInit  {
    @Input() cssSelector: string;
    @Input() withSelection: boolean;

    constructor(
        private element: ElementRef,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.element.nativeElement.querySelector(this.cssSelector)) {
                  this.element.nativeElement.querySelector(this.cssSelector).focus();
                  if (this.withSelection) {
                    this.element.nativeElement.querySelector(this.cssSelector).select();
                  }
                }
            }, 0);
        });
    }
}
