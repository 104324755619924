import {
  Component, Input
} from '@angular/core';

@Component({
  selector: 'troi-dropdown-option',
  templateUrl: './troi-dropdown-option.component.html',
  styleUrls: ['./troi-dropdown-option.component.scss']
})

export class TroiDropdownOptionComponent {
  @Input() public disabled = false;
  @Input() public selected = false;
  @Input() public active = true;
  @Input() public value: any;
  @Input() public label: string;
  @Input() public count: number;
  @Input() public loading: boolean;
  @Input() public group: boolean;
  @Input() public hasOverride: boolean;
}
