/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./headerNotification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/troi-icon/troi-icon.component.ngfactory";
import * as i5 from "../../shared/troi-icon/troi-icon.component";
import * as i6 from "./headerNotification.component";
import * as i7 from "../../core/storage/storage.listener";
var styles_HeaderNotificationComponent = [i0.styles];
var RenderType_HeaderNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderNotificationComponent, data: {} });
export { RenderType_HeaderNotificationComponent as RenderType_HeaderNotificationComponent };
function View_HeaderNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notification.message; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderNotificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(3, null, [": ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.reason; _ck(_v, 3, 0, currVal_1); }); }
function View_HeaderNotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "header-notification__content__multiple"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ul", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderNotificationComponent_4)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.notification.multipleMessage; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PriceList.error.multipleErrorLabel")); _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "alert header-notification"], ["role", "alert"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "header-notification--toast": 0, "alert-danger": 1, "alert-warning": 2, "alert-primary": 3, "alert-success": 4 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "header-notification__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderNotificationComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderNotificationComponent_3)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "header-notification__close pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeNotification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "troi-icon", [["icon", "icon-close"]], null, null, null, i4.View_TroiIconComponent_0, i4.RenderType_TroiIconComponent)), i1.ɵdid(10, 114688, null, 0, i5.TroiIconComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert header-notification"; var currVal_1 = _ck(_v, 2, 0, _co.asToast, _co.isError(), _co.isWarning(), _co.isInfo(), _co.isSuccess()); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.notification.message; _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.notification.multipleMessage.length > 0); _ck(_v, 7, 0, currVal_3); var currVal_4 = "icon-close"; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_HeaderNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderNotificationComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showNotification; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "header-notification", [], null, null, null, View_HeaderNotificationComponent_0, RenderType_HeaderNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i6.HeaderNotificationComponent, [i7.StorageListener], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderNotificationComponentNgFactory = i1.ɵccf("header-notification", i6.HeaderNotificationComponent, View_HeaderNotificationComponent_Host_0, { asToast: "asToast" }, {}, []);
export { HeaderNotificationComponentNgFactory as HeaderNotificationComponentNgFactory };
