<div class="troi-dropdown-option"
     [ngClass]="{
     'troi-dropdown-option--selected': selected && !group,
     'troi-dropdown-option--disabled': disabled,
     'troi-dropdown-option--group': group,
     'troi-dropdown-option--hasOverride': hasOverride}"
     *ngIf="active">
  <span class="troi-dropdown-option__label">
    {{ label }}
  </span>
  <span class="troi-dropdown-option__count" *ngIf="count !== undefined">
    ({{ count }})
  </span>
  <troi-loading class="troi-dropdown-option__loading" *ngIf="loading"></troi-loading>
</div>
