import { StorageListener } from '../storage/storage.listener';
import { StorageKeys } from '../storage/storage.keys';
import { StorageService } from '../storage/storage.service';
var ColorSwitcherService = /** @class */ (function () {
    function ColorSwitcherService(storageListener, storageService) {
        this.storageListener = storageListener;
        this.storageService = storageService;
    }
    Object.defineProperty(ColorSwitcherService.prototype, "color", {
        get: function () {
            var color = this.storageService.getItem(StorageKeys.THEME_COLOR);
            return color ? color : 'blue';
        },
        enumerable: true,
        configurable: true
    });
    ColorSwitcherService.prototype.switchColor = function (color) {
        this.storageService.setItem(StorageKeys.THEME_COLOR, color);
    };
    ColorSwitcherService.prototype.saveColor = function (color) {
        this.storageListener.store(StorageKeys.THEME_COLOR, color);
    };
    return ColorSwitcherService;
}());
export { ColorSwitcherService };
