import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';

interface Dictionary<T> { [key: string]: T; }

export enum BackendError {
    TRANSLATED = 'backendErrorTranslated',
    NOT_TRANSLATED = 'backendErrorNotTranslated'
}

@Injectable()
export class ErrorTranslationService {
    private errorMessages: Dictionary<string> = {
        required: 'Form.Error.Required',
        minlength: 'Form.Error.MinLength',
        email: 'Form.Error.Email'
    };

    public constructor(protected translationService: TranslateService) {}


    public getErrorMessages(errors) {
        const messages = [];
        _.forEach(errors, (interpolateParams, key) => {
            if (key === BackendError.TRANSLATED) {
                messages.push(interpolateParams.value);
            } else if (key === BackendError.NOT_TRANSLATED) {
                this.translationService.get(key, interpolateParams)
                    .subscribe((result: string) => {
                        messages.push(result);
                    });
            } else if (this.errorMessages[key]) {
                this.translationService.get(this.errorMessages[key], interpolateParams)
                    .subscribe((result: string) => {
                        messages.push(result);
                    });
            }
        });

        return messages;
    }
}
