export enum StorageKeys {
    ACTIVE_CURRENCY = 'activeCurrency',
    COUNTRIES = 'countries',
    PRICE_LIST_SETTINGS = 'priceListSettings',
    ACCOUNTING_SETTINGS = 'accountingSettings',
    USER = 'user',
    REMEMBER_ME = 'rememberMe',
    RETURN_URL = 'returnUrl',
    FILTERS = 'filters',
    DATA_LANG = 'dataLang',
    LANG = 'lang',
    THEME_COLOR = 'themeColor',
    NOTIFICATION = 'notification'
}
