import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColumnSelectInterface} from './column-select.interface';

@Component({
  selector: 'troi-columns-selector',
  templateUrl: './troi-columns-selector.component.html',
  styleUrls: ['./troi-columns-selector.component.scss']
})
export class TroiColumnsSelectorComponent {

    open = false;
    forceOpen = false;
    @Input() disabled = false;
    @Input() columnList: ColumnSelectInterface[] = [];
    @Output() columnsSaved = new EventEmitter<ColumnSelectInterface[]>();

    constructor() {
    }

    onOpen(state) {
        this.open = state;
    }

    toggleOpen() {
        this.open = !this.open;
        this.forceOpen = this.open;
    }

    onColumnsSaved(columns: ColumnSelectInterface[]) {
        this.columnsSaved.emit(columns);
    }
}
