import {
    Component
} from '@angular/core';

@Component({
    selector: 'troi-modal-content',
    templateUrl: './troi-modal-content.component.html',
    styleUrls: ['./troi-modal-content.component.scss']
})

export class TroiModalContentComponent {
}
