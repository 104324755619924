/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../repeat/repeat.directive";
import * as i4 from "./troi-loading.component";
var styles_TroiLoadingComponent = [i0.styles];
var RenderType_TroiLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiLoadingComponent, data: {} });
export { RenderType_TroiLoadingComponent as RenderType_TroiLoadingComponent };
function View_TroiLoadingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "troi-loading__column"]], [[4, "width", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "troi-loading__column--even": 0 })], function (_ck, _v) { var currVal_1 = "troi-loading__column"; var currVal_2 = _ck(_v, 2, 0, _v.context.even); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_TroiLoadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "troi-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "troi-loading__placeholder\n                troi-loading__placeholder--item\n                troi-loading__placeholder--animated-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "troi-loading__row"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "troi-loading__row--single": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiLoadingComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "troi-loading__row"; var currVal_1 = _ck(_v, 4, 0, !_co.columns.length); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.columns; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_TroiLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TroiLoadingComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.RepeatDirective, [i1.TemplateRef, i1.ViewContainerRef], { repeat: [0, "repeat"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rows; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TroiLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-loading", [], null, null, null, View_TroiLoadingComponent_0, RenderType_TroiLoadingComponent)), i1.ɵdid(1, 49152, null, 0, i4.TroiLoadingComponent, [], null, null)], null, null); }
var TroiLoadingComponentNgFactory = i1.ɵccf("troi-loading", i4.TroiLoadingComponent, View_TroiLoadingComponent_Host_0, { rows: "rows", columns: "columns" }, {}, []);
export { TroiLoadingComponentNgFactory as TroiLoadingComponentNgFactory };
