import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbInterface} from './breadcrumb.interface';

@Component({
  selector: 'troi-breadcrumbs',
  templateUrl: './troi-breadcrumbs.component.html',
  styleUrls: ['./troi-breadcrumbs.component.scss']
})
export class TroiBreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs: BreadcrumbInterface[] = [];

  constructor() { }

  ngOnInit() {
  }

}
