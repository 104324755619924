import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
    public getItem(name: string) {
        return JSON.parse(localStorage.getItem(name));
    }

    public setItem(key: string, item: any) {
        localStorage.setItem(key, JSON.stringify(item));
    }

    public removeItem(key: string) {
        localStorage.removeItem(key);
    }
}
