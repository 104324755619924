<div *ngIf="showNotification"
     class="alert header-notification"
     [ngClass]="{'header-notification--toast' : asToast, 'alert-danger': isError(), 'alert-warning': isWarning(), 'alert-primary': isInfo(), 'alert-success': isSuccess() }"
    role="alert">
    <div class="header-notification__content">
        <span *ngIf="notification.message">{{ notification.message }}</span>
        <div class="header-notification__content__multiple" *ngIf="notification.multipleMessage.length > 0">
            <ul class="list">{{'PriceList.error.multipleErrorLabel' | translate}}:
                <li class="list__item" *ngFor="let item of notification.multipleMessage">
                    <strong>{{item.name}}</strong>: {{item.reason}}
                </li>
            </ul>
        </div>
    </div>
    <div class="header-notification__close pointer" (click)="closeNotification()">
        <troi-icon icon="icon-close"></troi-icon>
    </div>
</div>
