var _a, _b;
export var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes[ErrorCodes["GLOBAL"] = 1] = "GLOBAL";
    ErrorCodes[ErrorCodes["RESET_PASSWORD_DEFAULT"] = 1040000] = "RESET_PASSWORD_DEFAULT";
    ErrorCodes[ErrorCodes["RESET_PASSWORD_USER_NOT_FOUND"] = 1040001] = "RESET_PASSWORD_USER_NOT_FOUND";
    ErrorCodes[ErrorCodes["RESET_PASSWORD_USER_BLOCKED"] = 1040002] = "RESET_PASSWORD_USER_BLOCKED";
    ErrorCodes[ErrorCodes["RESET_PASSWORD_PROBLEM_WITH_SENDING_EMAIL"] = 1040003] = "RESET_PASSWORD_PROBLEM_WITH_SENDING_EMAIL";
})(ErrorCodes || (ErrorCodes = {}));
export var SuccessCodes;
(function (SuccessCodes) {
    SuccessCodes[SuccessCodes["GLOBAL"] = 1] = "GLOBAL";
    SuccessCodes[SuccessCodes["RESET_PASSWORD_SUCCESS"] = 2] = "RESET_PASSWORD_SUCCESS";
})(SuccessCodes || (SuccessCodes = {}));
export var ErrorCodeTranslations = (_a = {},
    _a[ErrorCodes.GLOBAL] = 'Backend.Error.Global',
    _a[ErrorCodes.RESET_PASSWORD_USER_NOT_FOUND] = 'Backend.ResettingPassword.Error.UserNotFound',
    _a[ErrorCodes.RESET_PASSWORD_USER_BLOCKED] = 'Backend.ResettingPassword.Error.UserBlocked',
    _a[ErrorCodes.RESET_PASSWORD_PROBLEM_WITH_SENDING_EMAIL] = 'Backend.ResettingPassword.Error.Mail',
    _a[ErrorCodes.RESET_PASSWORD_DEFAULT] = 'Backend.ResettingPassword.Error.General',
    _a);
export var SuccessCodeTranslations = (_b = {},
    _b[SuccessCodes.GLOBAL] = 'Backend.Success.Global',
    _b[SuccessCodes.RESET_PASSWORD_SUCCESS] = 'Backend.ResettingPassword.Success.PasswordReset',
    _b);
