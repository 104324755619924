function _history() {
    return window.parent.history;
}
function _window() {
    return window.parent;
}
var ParentWindowRef = /** @class */ (function () {
    function ParentWindowRef() {
    }
    Object.defineProperty(ParentWindowRef.prototype, "nativeHistory", {
        get: function () {
            return _history();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ParentWindowRef.prototype, "nativeWindow", {
        get: function () {
            return _window();
        },
        enumerable: true,
        configurable: true
    });
    return ParentWindowRef;
}());
export { ParentWindowRef };
