import * as tslib_1 from "tslib";
import { EventStrategies } from './event-strategies.enum';
import { BaseStrategy } from './base.strategy';
var FocusStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(FocusStrategy, _super);
    function FocusStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FocusStrategy.prototype.support = function (type) {
        return type === EventStrategies.FOCUS;
    };
    FocusStrategy.prototype.handle = function (element) {
        var event = new MouseEvent('focus', { bubbles: true });
        this.invokeElementMethod(element.nativeElement, 'dispatchEvent', [event]);
    };
    return FocusStrategy;
}(BaseStrategy));
export { FocusStrategy };
