/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./string.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./string.component";
var styles_StringComponent = [i0.styles];
var RenderType_StringComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StringComponent, data: {} });
export { RenderType_StringComponent as RenderType_StringComponent };
export function View_StringComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "label", [["class", "checkboxContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["type", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "checkmark"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["KSK"]))], null, null); }
export function View_StringComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "string", [], null, null, null, View_StringComponent_0, RenderType_StringComponent)), i1.ɵdid(1, 49152, null, 0, i2.StringComponent, [], null, null)], null, null); }
var StringComponentNgFactory = i1.ɵccf("string", i2.StringComponent, View_StringComponent_Host_0, { content: "content" }, {}, []);
export { StringComponentNgFactory as StringComponentNgFactory };
