export enum BackendResponseType {
    TYPE_OBJECT     = 'object',
    TYPE_COLLECTION = 'collection',
    TYPE_ERROR      = 'error',
    TYPE_STRING     = 'string',
    TYPE_NUMBER     = 'number'
}

export enum AccessRight {
    READ = 'read',
    WRITE = 'write',
    NONE = 'none'
}

export interface BackendResponseInterface<T> {
    type: BackendResponseType;
    data: T;
    totalCount?: number;
    access?: AccessRight;
}

export interface ResponseStatusInterface {
    success: boolean;
    reason: string;
}
