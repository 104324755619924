import {
    Component,
    Input,
    OnInit,
    OnChanges,
    ViewChild,
    ChangeDetectorRef,
    forwardRef, HostListener,
} from '@angular/core';
import {ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'troi-input-field',
  templateUrl: './troi-input-field.component.html',
  styleUrls: ['./troi-input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiInputFieldComponent),
      multi: true
    }
  ]
})

export class TroiInputFieldComponent implements OnChanges, OnInit, ControlValueAccessor {

  @ViewChild(DefaultValueAccessor) private valueAccessor: DefaultValueAccessor;
  @Input() public value: any;
  @Input() public enabled = true;
  @Input() public fieldInvalid = false;
  @Input() public validationEnabled = false;
  @Input() public numbersOnly = false;
  @Input() public integersOnly = false;
  @Input() public type = 'text';

  constructor(
    private cdRef: ChangeDetectorRef
  ) {}

  writeValue(obj: any) {
    this.valueAccessor.writeValue(obj);
  }

  registerOnChange(fn: any) {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.valueAccessor.setDisabledState(isDisabled);
  }

  ngOnInit() {
    if (this.value) {
      this.valueAccessor.writeValue(this.value);
    }
  }

  ngOnChanges(changes) {
    if (changes.value) {
      this.valueAccessor.writeValue(this.value);
      this.cdRef.detectChanges();
    }
  }

  @HostListener ('focusout')
    focusout() {
      if (this.value) {
        this.valueAccessor.writeValue(this.value);
      }
  }

  filterContent(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (this.integersOnly) {
        return !(this.numbersOnly && charCode > 31 && (charCode < 48 || charCode > 57));
    }
    return charCode === 44 || charCode ===  46 ||  !(this.numbersOnly && charCode > 31 && (charCode < 48 || charCode > 57));
  }
}
