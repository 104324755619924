import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './not-found.component';
import {AuthGuard} from './security/auth.guard';
import {HeaderNotificationComponent} from './modules/header-notification/headerNotification.component';
import {AccountsComponent} from './modules/accounts/accounts.component';

const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: './login/login.module#LoginModule',
    },
    {
        path: 'pricelist',
        loadChildren: './modules/price-list/priceList.module#PriceListModule',
        // canActivate: [AuthGuard]
    },
    {
        path: 'notification',
        component: HeaderNotificationComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'logout',
        redirectTo: '/login/logout',
        pathMatch: 'full'
    },
    {
        path: 'accounts',
        component: AccountsComponent,
        children: [{
            path: 'accounting-administration/groups',
            loadChildren: './modules/accounts/accounting-administration/account-group/account-group.module#AccountGroupModule',
        },
        {
            path: 'accounting-administration',
            loadChildren: './modules/accounts/accounting-administration/account-list/account-list.module#AccountListModule',
        },
        {
            path: 'accounting-administration/costcenters',
            loadChildren: './modules/accounts/accounting-administration/cost-center/cost-center.module#CostCenterModule',
        },
        {
            path: 'accounting/uploaded-files',
            loadChildren: './modules/accounts/accounting/uploaded-files/uploaded-files.module#UploadedFilesModule',
        },
        {
            path: 'accounting/assigned',
            loadChildren: './modules/accounts/accounting/assigned/assigned.module#AssignedModule',
        },
        {
            path: 'accounting/approved',
            loadChildren: './modules/accounts/accounting/approved/approved.module#ApprovedModule',
        },
        {
            path: 'accounting/import-export',
            loadChildren: './modules/accounts/accounting/import-export/import-export.module#ImportExportModule',
        },
        {
            path: 'accounting/recorded',
            loadChildren: './modules/accounts/accounting/recorded/recorded.module#RecordedModule',
        },
        ]
    },
    {path: '**', component: PageNotFoundComponent}
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                enableTracing: false,
            }
        )
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard,
    ]
})
export class AppRoutingModule {}
