import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[repeat]'
})
export class RepeatDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) { }

    @Input() set repeat(times: number) {
        for (let i = 0 ; i < times ; i++) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
