import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { StorageService } from '../storage/storage.service';
var StorageListener = /** @class */ (function () {
    function StorageListener(storageService) {
        this.storageService = storageService;
        this.onSubject = new Subject();
        this.start();
    }
    StorageListener.prototype.ngOnDestroy = function () {
        this.stop();
    };
    Object.defineProperty(StorageListener.prototype, "storageChanges", {
        get: function () {
            return this.onSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StorageListener.prototype.getStorage = function () {
        var s = [];
        for (var i = 0; i < localStorage.length; i++) {
            s.push({
                key: localStorage.key(i),
                value: this.storageService.getItem(localStorage.key(i))
            });
        }
        return s;
    };
    StorageListener.prototype.store = function (key, data) {
        this.storageService.setItem(key, data);
        this.onSubject.next({ key: key, value: data });
    };
    StorageListener.prototype.clear = function (key) {
        this.storageService.removeItem(key);
    };
    StorageListener.prototype.start = function () {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    };
    StorageListener.prototype.storageEventListener = function (event) {
        if (event.storageArea === localStorage) {
            var v = void 0;
            try {
                v = JSON.parse(event.newValue);
            }
            catch (e) {
                v = event.newValue;
            }
            this.onSubject.next({ key: event.key, value: v });
        }
    };
    StorageListener.prototype.stop = function () {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
        this.onSubject.complete();
    };
    return StorageListener;
}());
export { StorageListener };
