import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Injectable()
export class TroiTranslateLoader implements TranslateLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {
        if (environment.production) {
            return new Observable(observer => {
                this.http.get('/assets/i18n/' + lang + '.json').subscribe((res: Response) => {
                    observer.next(res);
                    observer.complete();
                });
            });
        }

        return new Observable(observer => {
            const params = new HttpParams().set('ignoreUrlInterception', 'true'),
                contentHeader: HttpHeaders = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                });

            this.http.get('/assets/i18n/' + lang + '.json', {
                headers: contentHeader,
                params: params
            }).subscribe((res: Response) => {
                observer.next(res);
                observer.complete();
            });
        });
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TroiTranslateLoader(http);
}

export let TroiHttpLoaderFactory = {
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
};
