import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AccountingSettingsService} from '../services/accounting-settings.service';

@Injectable()
export class AccountingSettingsInterceptor implements HttpInterceptor {
    blocked = false;
    constructor(
        private settingService: AccountingSettingsService,
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.overrideRequest(req, next);
    }

    private overrideRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                let settings = this.settingService.settings ? this.settingService.settings : this.settingService.getSettingsFromLS();
                if (settings && !this.blocked) {
                    if (!settings.lastDownload) {
                        settings.lastDownload = parseInt(event.headers.get('settings-last-modified'));
                        this.settingService.saveSettingsToLS(settings);
                    }
                    if (settings.lastDownload && parseInt(event.headers.get('settings-last-modified')) > settings.lastDownload) {
                        this.blocked = true;
                        const actualFromLS = this.settingService.getSettingsFromLS();
                        if (actualFromLS && settings.client !== actualFromLS.client) {
                            settings = actualFromLS;
                        }
                        this.settingService.network.downloadSettings(settings.client).subscribe((result) => {
                            this.settingService.settings = result.data;
                            this.settingService.settings.lastDownload = parseInt(event.headers.get('settings-last-modified'));
                            this.settingService.saveSettingsToLS(this.settingService.settings);
                            this.blocked = false;
                        });
                    }
                }
                return event;
            }
            return event;
        });
    }
}

export let AccountingSettingsInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AccountingSettingsInterceptor,
    multi: true
};
