import * as _ from 'lodash';
var Money = /** @class */ (function () {
    function Money(value, currency, decimalPlaces) {
        this.value = value;
        this.currency = currency;
        this.decimalPlaces = decimalPlaces;
        this.uniqueSeparator = 'uniqueSeparator';
        this.init();
    }
    Money.prototype.init = function () {
        this.numberValue = parseFloat(this.value).toFixed(this.decimalPlaces).toString();
        this.formattedValue = this.formatValue(this.numberValue);
    };
    Object.defineProperty(Money.prototype, "money", {
        get: function () {
            return this.formattedValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Money.prototype, "editableMoney", {
        get: function () {
            return _.clone(this.numberValue).replace('.', this.currency.format.decimalMark);
        },
        enumerable: true,
        configurable: true
    });
    Money.prototype.update = function (value) {
        if (value) {
            this.numberValue = value.replace(this.currency.format.decimalMark, this.uniqueSeparator);
            this.numberValue = this.parseNumber(this.numberValue);
            this.numberValue = this.numberValue.replace(this.uniqueSeparator, this.currency.format.decimalMark);
            this.formattedValue = this.formatValue(this.numberValue);
            this.value = this.numberValue.replace(this.currency.format.decimalMark, '.');
        }
        return this.money;
    };
    Money.prototype.parseNumber = function (newValue) {
        var splitted = newValue.split(this.uniqueSeparator);
        splitted[0] = splitted[0].replace(/[^\-\+\d]/g, '');
        if (!splitted[1]) {
            splitted[1] = '0000';
        }
        splitted[1] = splitted[1].replace(/[^\d]/g, '');
        return splitted.join(this.uniqueSeparator);
    };
    Object.defineProperty(Money.prototype, "forBackend", {
        get: function () {
            return this.numberValue.replace(this.currency.format.decimalMark, '.');
        },
        enumerable: true,
        configurable: true
    });
    Money.prototype.formatValue = function (value) {
        var number = parseFloat(value.replace(this.currency.format.decimalMark, '.')).toFixed(this.decimalPlaces), splittedNumber = number.toString().split('.');
        var formattedValue = splittedNumber[0];
        for (var i = splittedNumber[0].length - 3; i > 0; i -= 3) {
            formattedValue = formattedValue.slice(0, i) + this.currency.format.thousandsMark + formattedValue.slice(i);
        }
        formattedValue += this.currency.format.decimalMark + splittedNumber[1];
        return formattedValue;
    };
    Object.defineProperty(Money.prototype, "formattedValueWithCurrencyMark", {
        get: function () {
            if (this.currency.format.isSymbolBehind) {
                return this.formattedValue + ' ' + this.currency.symbol;
            }
            return this.currency.symbol + ' ' + this.formattedValue;
        },
        enumerable: true,
        configurable: true
    });
    Money.prototype.isZero = function () {
        var numberValue = parseFloat('0').toFixed(this.decimalPlaces).toString();
        return this.formattedValue === this.formatValue(numberValue);
    };
    return Money;
}());
export { Money };
