/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./troi-btn.component";
var styles_TroiBtnComponent = [i0.styles];
var RenderType_TroiBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiBtnComponent, data: {} });
export { RenderType_TroiBtnComponent as RenderType_TroiBtnComponent };
export function View_TroiBtnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "troi-btn"]], [[8, "disabled", 0], [8, "type", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "troi-btn--gray": 0, "troi-btn--outline": 1 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "troi-btn"; var currVal_3 = _ck(_v, 2, 0, _co.disabled, _co.outline); _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = i1.ɵinlineInterpolate(1, "", (_co.submit ? "submit" : "button"), ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TroiBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-btn", [], null, null, null, View_TroiBtnComponent_0, RenderType_TroiBtnComponent)), i1.ɵdid(1, 49152, null, 0, i3.TroiBtnComponent, [], null, null)], null, null); }
var TroiBtnComponentNgFactory = i1.ɵccf("troi-btn", i3.TroiBtnComponent, View_TroiBtnComponent_Host_0, { disabled: "disabled", submit: "submit", outline: "outline" }, {}, ["*"]);
export { TroiBtnComponentNgFactory as TroiBtnComponentNgFactory };
