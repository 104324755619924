import {StorageService} from '../storage/storage.service';

export class UserSettings {
    userSettingsKey = 'userSettings';
    userKey = 'user';
    userId;

    constructor(public storage: StorageService) {
        this.userId = this.storage.getItem(this.userKey)['id'];
    }

    getUserId() {
        return this.userId ? this.userId : this.storage.getItem(this.userKey)['id'];
    }

    initLSUserData(settingKey: string) {
        if (!this.storage.getItem(this.userSettingsKey)) {
            const settings = {};
            settings[this.userId] = {};
            settings[this.userId][settingKey] = {};
            this.storage.setItem(this.userSettingsKey, settings);
        } else if (!this.storage.getItem(this.userSettingsKey)[this.userId]) {
            const settings = this.storage.getItem(this.userSettingsKey);
            settings[this.userId] = {};
            settings[this.userId][settingKey] = {};
            this.storage.setItem(this.userSettingsKey, settings);
        } else if (!this.storage.getItem(this.userSettingsKey)[this.userId][settingKey]) {
            const settings = this.storage.getItem(this.userSettingsKey);
            settings[this.userId][settingKey] = {};
            this.storage.setItem(this.userSettingsKey, settings);
        }
    }

    getAllUsersSettings() {
        return this.storage.getItem(this.userSettingsKey);
    }

    getUserSettings() {
        return this.storage.getItem(this.userSettingsKey)[this.getUserId()];
    }

    getSettingForUser(settingKey: string) {
        return this.getUserSettings()[settingKey];
    }

    saveUserSetting(data) {
        this.storage.setItem(this.userSettingsKey, data);
    }
}
