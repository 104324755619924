<form class="settings" [formGroup]="form">
    <troi-modal-header>
        <span>{{'Booking.labels.settings' | translate}}</span>
    </troi-modal-header>
    <troi-modal-content style="overflow-y: inherit">
        <troi-modal-row>
            <div row-label>{{'Booking.labels.itemsPerPage' | translate}}</div>
            <div row-value class="settings__row">
                <troi-input-field
                        [numbersOnly]="true"
                        [integersOnly]="true"
                        [fieldInvalid]="!isNumberValid()"
                        row-value
                        formControlName="itemsPerPage">
                </troi-input-field>
                <div class="settings__row__error">
                    <span *ngIf="!isNumberValid()">{{'Booking.labels.range1To1000' | translate}}</span>
                </div>
            </div>
        </troi-modal-row>
    </troi-modal-content>

    <troi-modal-footer>
        <troi-btn
                [disabled]="!isNumberValid()"
                (click)="save()">
            {{'Booking.labels.save' | translate}}
        </troi-btn>
    </troi-modal-footer>
</form>
