import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TroiDropDownCloseService implements OnDestroy {
    private subject = new Subject<Event>();

    constructor() {
        window.addEventListener('click', this.close.bind(this));
    }

    ngOnDestroy() {
        this.stop();
    }

    close(event: Event) {
        event.stopPropagation();
        this.subject.next(event);
    }

    getEmitter(): Observable<any> {
        return this.subject.asObservable();
    }

    private stop(): void {
        window.removeEventListener('click',  this.close.bind(this));
        this.subject.complete();
    }
}
