/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./troi-modal-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./troi-modal-footer.component";
var styles_TroiModalFooterComponent = [i0.styles];
var RenderType_TroiModalFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TroiModalFooterComponent, data: {} });
export { RenderType_TroiModalFooterComponent as RenderType_TroiModalFooterComponent };
export function View_TroiModalFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "modal-footer__options"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "modal-footer__buttons"]], null, null, null, null, null)), i1.ɵncd(null, 1)], null, null); }
export function View_TroiModalFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "troi-modal-footer", [], null, null, null, View_TroiModalFooterComponent_0, RenderType_TroiModalFooterComponent)), i1.ɵdid(1, 49152, null, 0, i2.TroiModalFooterComponent, [], null, null)], null, null); }
var TroiModalFooterComponentNgFactory = i1.ɵccf("troi-modal-footer", i2.TroiModalFooterComponent, View_TroiModalFooterComponent_Host_0, {}, {}, ["[footer-options]", "*"]);
export { TroiModalFooterComponentNgFactory as TroiModalFooterComponentNgFactory };
